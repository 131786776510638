import React from "react";
import { Row, Col } from "reactstrap";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa";
import { galaxyKids } from "../assets/styles/globle";
import { useSettings } from '../context/CountryContext';

const Footer = () => {
  
  const { configuredSettings, loading } = useSettings();
  return (
    <footer className="bg-primary text-white text-center py-3 mt-4">
      <div className="container-fluid">
        <div className="row g-3 justify-content-between align-items-center">
          <small className="col-md-6 text-md-start">
            &copy; Quippy Copyright 2024. All Rights Reserved.
          </small>
          <div className="col-md-6 text-md-end text-white">
            { configuredSettings?.data?.social_media_links && configuredSettings?.data?.social_media_links?.facebook && (<a
              href={configuredSettings?.data?.social_media_links?.facebook}
              className="text-white mx-2"
              style={{ color: galaxyKids.textOrange }}
            >
              <FaFacebook size={25} />
            </a>)}
            { configuredSettings?.data?.social_media_links && configuredSettings?.data?.social_media_links?.twitter && (<a
              href={configuredSettings?.data?.social_media_links?.twitter}
              className="text-white mx-2"
              style={{ color: galaxyKids.textOrange }}
            >
              <FaTwitter size={25} />
            </a>)}
            { configuredSettings?.data?.social_media_links && configuredSettings?.data?.social_media_links?.linkedin && (<a
              href={configuredSettings?.data?.social_media_links?.linkedin}
              className="text-white mx-2"
              style={{ color: galaxyKids.textOrange }}
            >
              <FaLinkedin size={25} />
            </a>)}
            { configuredSettings?.data?.social_media_links && configuredSettings?.data?.social_media_links?.instagram && (<a
              href={configuredSettings?.data?.social_media_links?.instagram}
              className="text-white mx-2"
              style={{ color: galaxyKids.textOrange }}
            >
              <FaInstagram size={25} />
            </a>)}
            { configuredSettings?.data?.social_media_links && configuredSettings?.data?.social_media_links?.youtube && (<a
              href="https://www.youtube.com/channel/UCU_zRpAA31criOhv8CAY7Qg"
              className="text-white mx-2"
              style={{ color: galaxyKids.textOrange }}
            >
              <FaYoutube size={25} />
            </a>)}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
