import { Button, Result } from "antd";
import React, { useEffect } from "react";
import { SmileOutlined } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import { galaxyKids } from "../../assets/styles/globle";
import Header from "../Header";
import { Col, Progress, Row } from "reactstrap";
import Footer from "../FooterPart";
import { useSettings } from '../../context/CountryContext';
import DOMPurify from 'dompurify';
import styled from "styled-components";

const Wrapper = styled.div`
  min-height: 100vh;
  padding-top: 62.84px;

  @media (min-width: 768px) {
    padding-top: 76px;
  }
`;

const MainContent = styled.div`
  padding: 1rem;

  @media (min-width: 768px) {
    padding: 2rem;
  }
`;

const Thanks = () => {

    const { configuredSettings, loading } = useSettings();

    const handleSubmit = () => {
        localStorage.setItem("success", 100);
    };
    const { pathname } = useLocation();

    // Automatically scrolls to top whenever pathname changes
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (
        <div
            style={{
                backgroundColor: galaxyKids.backgroundColor,
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Header />

            <Wrapper>
                <MainContent>
                    <Progress color="success" className="my-3" value={100} />
                    <Row className="justify-content-center">
                        <Col lg={12} className="text-center">
                            <Result className="thanks-content p-0 mt-4"
                                icon={
                                    configuredSettings?.data?.emoji ? (<img
                                        src={`${process.env.REACT_APP_BASE_URL}/api/emoji/${configuredSettings?.data?.emoji}`}
                                        alt="Success"
                                        style={{ width: "60px", height: "60px", objectFit: "cover" }}  // Adjust styles as needed
                                    />) : (
                                        <SmileOutlined
                                            style={{ color: galaxyKids.textOrange, fontSize: "60px" }}
                                        />
                                    )
                                }
                                title={<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(configuredSettings?.data?.message) }} />}
                                extra={
                                    <Link to="/profile">
                                        <Button
                                            style={{
                                                backgroundColor: galaxyKids.textOrange,
                                                color: "white",
                                                border: "none",
                                            }}
                                            size="large"
                                            onClick={handleSubmit}
                                        >
                                            Go to Profile
                                        </Button>
                                    </Link>
                                }
                            />
                        </Col>
                    </Row>
                </MainContent>
            </Wrapper>
            <Footer />
        </div>
    );
};

export default Thanks;
