import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import AdminLogin from "./AdminLogin";

const AdminRoutes = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/galaxykids/admin/login" element={<AdminLogin />} />
          {/* <Route path="/galaxykids/admin/dashboard" element={<Admin />} /> */}
        </Routes>
      </Router>
    </>
  );
};

export default AdminRoutes;
