import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import { Button, Checkbox, Form, Input, message, Upload } from "antd";
import { galaxyKids } from "../../assets/styles/globle";
import axios from "axios";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { UploadOutlined } from "@ant-design/icons";

const AddContactDetails = (props) => {
  const { addContactDetailsModalOpen, setAddContactDetailsModalOpen, teacerData, isUpdate } = props;
  const [form] = Form.useForm();
  const [phone, setPhone] = useState("");
  const [whatsappFile, setWhatsappFile] = useState(null);
  const [instagramFile, setInstagramFile] = useState(null);
  const [lineFile, setLineFile] = useState(null);
  const [wechatFile, setWechatFile] = useState(null);

  const handleCancel = () => {
    setAddContactDetailsModalOpen(false);
  };

  useEffect(() => {
    if (teacerData) {
      form.setFieldsValue({
        mobile_number: teacerData?.contact_details?.mobile_number || '',
        website: teacerData?.contact_details?.website,
        email: teacerData?.email,
        remember: teacerData?.contact_details?.isPublic, // Set initial value of the checkbox
      });
      setPhone(teacerData?.contact_details?.mobile_number || '')
    }

    if (teacerData?.contact_details?.whatsapp && teacerData?.contact_details?.whatsapp !== 'null') {
      setWhatsappFile({
        name: teacerData.contact_details.whatsapp 
      });
    }
    if (teacerData?.contact_details?.instagram && teacerData?.contact_details?.instagram !== 'null') {
      setInstagramFile({
        name: teacerData.contact_details.instagram
      });
    }
    if (teacerData?.contact_details?.line && teacerData?.contact_details?.line !== 'null') {
      setLineFile({
        name: teacerData.contact_details.line
      });
    }
    if (teacerData?.contact_details?.wechat && teacerData?.contact_details?.wechat !== 'null') {
      setWechatFile({
        name: teacerData.contact_details.wechat
      });
    }

  }, [teacerData]);

  const onFinish = async (values) => {
    let data = new FormData();
    if (whatsappFile && whatsappFile.name !== teacerData?.contact_details?.whatsapp) {
      data.append("whatsapp", whatsappFile);
    }
    if (instagramFile && instagramFile.name !== teacerData?.contact_details?.instagram) {
      data.append("instagram", instagramFile);
    }
    if (lineFile && lineFile.name !== teacerData?.contact_details?.line) {
      data.append("line", lineFile);
    }
    if (wechatFile && wechatFile.name !== teacerData?.contact_details?.wechat) {
      data.append("wechat", wechatFile);
    }
    if (values.email) {
      data.append("email", values.email);
    }
    if (values.website) {
      data.append("website", values.website);
    }
    if (values.mobile_number) {
      data.append("mobile_number", phone);
    }
    data.append("isPublic", values.remember || false); // Add the checkbox value
  
    const token = localStorage.getItem("accessToken");
    const teacherId = localStorage.getItem("teacherId");
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/teacher/contact-details/${teacherId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      message.success("Contact details saved successfully!");
      form.resetFields(); // Clear the form fields
      setWhatsappFile(null);
      setInstagramFile(null);
      setLineFile(null);
      setWechatFile(null);
      handleCancel(); // Close the modal after successful submission
    } catch (error) {
      console.error("Error saving contact details:", error);
      message.error("Failed to save contact details. Please try again.");
    }
  };

  const handleUpload = ({ file }, platform) => {
    const formData = new FormData();
    // Append the file to FormData based on the platform
    switch (platform) {
      case "whatsapp":
        formData.append("whatsapp", file);
        setWhatsappFile(file); // Update state for WhatsApp file
        break;
      case "instagram":
        formData.append("instagram", file);
        setInstagramFile(file); // Update state for Instagram file
        break;
      case "line":
        formData.append("line", file);
        setLineFile(file); // Update state for Line file
        break;
      case "wechat":
        formData.append("wechat", file);
        setWechatFile(file); // Update state for WeChat file
        break;
      default:
        break;
    }
  };

  const handleRemove = (key) => async () => {
    const token = localStorage.getItem("accessToken");
    const teacherId = localStorage.getItem("teacherId");
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/teacher/contact-details/remove-image/${key}/${teacherId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      message.success("Qr Code removed successfully.");
    } catch (error) {
      message.error("Failed to remove video.");
      console.error("Failed to remove video", error);
    }
    switch (key) {
      case "whatsapp":
        setWhatsappFile(null); // Remove WhatsApp file
        break;
      case "instagram":
        setInstagramFile(null); // Remove Instagram file
        break;
      case "line":
        setLineFile(null); // Remove Line file
        break;
      case "wechat":
        setWechatFile(null); // Remove WeChat file
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Modal
        isOpen={addContactDetailsModalOpen}
        toggle={handleCancel}
        contentClassName="custom-modal-content"
        backdropClassName="custom-modal-backdrop"
      >
        <ModalHeader
          toggle={handleCancel}
          style={{
            fontSize: "18px",
            fontWeight: "bold",
            color: galaxyKids.textOrange,
            backgroundColor: galaxyKids.backgroundColor,
          }}
        >
          Contact Details
        </ModalHeader>
        <ModalBody
          className="custom-modal-body"
          style={{
            backgroundColor: galaxyKids.backgroundColor,
          }}
        >
          <Form
            layout="vertical"
            form={form}
            name="contactDetails"
            onFinish={onFinish}
            style={{
              backgroundColor: galaxyKids.backgroundColor,
            }}
          >
            <Row>
              <Col md={6}>
                <Form.Item
                  name="mobile_number"
                  label="Mobile Number"
                  rules={[
                    { required: true, message: "Please enter Mobile Number!" },
                  ]}
                >
                  <PhoneInput
                    style={{ borderRadius: "9px" }}
                    defaultCountry="in"
                    isValid={true}
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                  />
                </Form.Item>
              </Col>

              <Col md={6}>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    { required: true, message: "Please input your email!" },
                  ]}
                >
                  <Input size="large" placeholder="Add Email " />
                </Form.Item>
              </Col>

              <Col md={6}>
                <Form.Item
                  name="website"
                  label="Website URL"
                  rules={[
                    {
                      type: 'url',
                      message: 'Please enter a valid URL!',
                    },
                  ]}
                >
                  <Input size="large" placeholder="Website URL" />
                </Form.Item>
              </Col>

              <Col md={6}>
                <Form.Item
                  name="whatsapp"
                  label="QR code WhatsApp"
                >
                  <Upload
                    accept=".png,.jpg,.jpeg"
                    customRequest={(info) => handleUpload(info, "whatsapp")}
                    fileList={whatsappFile ? [whatsappFile] : []}
                    onRemove={handleRemove("whatsapp")}
                  >
                    <Button size="large" icon={<UploadOutlined />}>
                      Upload WhatsApp
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>

              <Col md={6}>
                <Form.Item
                  name="instagram"
                  label="QR code Instagram"
                >
                  <Upload
                    accept=".png,.jpg,.jpeg"
                    customRequest={(info) => handleUpload(info, "instagram")}
                    fileList={instagramFile ? [instagramFile] : []}
                    onRemove={handleRemove("instagram")}
                  >
                    <Button size="large" icon={<UploadOutlined />}>
                      Upload Instagram
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
              <Col md={6}>
                <Form.Item
                  name="line"
                  label="QR code Line"
                >
                  <Upload
                    accept=".png,.jpg,.jpeg"
                    customRequest={(info) => handleUpload(info, "line")}
                    fileList={lineFile ? [lineFile] : []}
                    onRemove={handleRemove("line")}
                  >
                    <Button size="large" icon={<UploadOutlined />}>
                      Upload Line
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>

              <Col md={6}>
                <Form.Item
                  name="wechat"
                  label="QR code WeChat"
                >
                  <Upload
                    accept=".png,.jpg,.jpeg"
                    customRequest={(info) => handleUpload(info, "wechat")}
                    fileList={wechatFile ? [wechatFile] : []}
                    onRemove={handleRemove("wechat")}
                  >
                    <Button size="large" icon={<UploadOutlined />}>
                      Upload WeChat
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row className="d-flex justify-content-start">
              <Form.Item name="remember" valuePropName="checked">
                <Checkbox size="large" style={{ color: galaxyKids.textColor }}>
                  Would you like your contact information to be publicly visible?
                </Checkbox>
              </Form.Item>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter
          className="custom-modal-footer"
          style={{
            backgroundColor: galaxyKids.backgroundColor,
          }}
        >
          <Button
            className="border-0"
            style={{
              color: galaxyKids.textOrange,
              backgroundColor: galaxyKids.backgroundColor,
            }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            className="border-0"
            style={{ backgroundColor: galaxyKids.textOrange, color: "#fff" }}
            onClick={() => form.submit()}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddContactDetails;
