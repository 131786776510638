export const globleColor = {
  whiteColorTheme: "white",
  blueColorTheme: "#1677ff",
  silverColorTheme: "#CCCCCC",
  silverColorText: "#a9a9a9",
  blackColorTheme: "#000",
  darkSilverText: "#363636",
};

export const textChnages = {
  nonetextDecoration: "none",
  smallTextSize: "16px",
  moderateTextSize: "23px",
  headingTextSize: "30px",
  midiumTextSize: "42px",
  largeTextSize: "66px",
  iconSizeMedium: "29px",
};

export const inputFieldSize = {
  inputFieldHeight: "48px",
};

export const galaxyKids = {
  borderColor: "#b200b2",
  textColor: "#040AA3",
  textOrange: "#b200b2",
  iconColor: "#0dcaf0",
  backgroundColor: "#E7F9FF",
  whiteBackground: "#FFFFFF",
};
