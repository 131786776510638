import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button, Form, Input, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { galaxyKids } from "../../assets/styles/globle";
import axios from "axios";

const AddCertificate = (props) => {
  const { addCertificateModalOpen, setAddCertificateModalOpen } = props;
  const [certificateFile, setCertificateFile] = useState(null);
  const [form] = Form.useForm();

  const handleCancel = () => {
    setAddCertificateModalOpen(false);
  };

  const onFinish = async () => {
    try {
      const formData = new FormData();
      formData.append("certificate", certificateFile);
      const token = localStorage.getItem("accessToken");
      const teacherId = localStorage.getItem("teacherId");
      await axios.post(
        `${process.env.REACT_APP_API_URL}/teacher/basic-details/${teacherId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      message.success("Certificate saved successfully!");
      handleCancel(); // Close the modal after successful submission
    } catch (error) {
      console.error("Error saving certificate:", error);
      handleCancel(); // Close the modal after successful submission
      // message.error("Failed to save certificate. Please try again.");
    }
  };

  const onChange = (info) => {
    setCertificateFile(info.file.originFileObj);
  };

  const beforeUpload = (file) => {
    const isPDF = file.type === "application/pdf";
    if (!isPDF) {
      message.error("You can only upload PDF files!");
    }
    return isPDF;
  };

  return (
    <>
      {/* <Button
        style={{
          color: galaxyKids.textColor,
          backgroundColor: galaxyKids.backgroundColor,
          borderColor: galaxyKids.textColor,
          width: "100%",
        }}
        className="border-1"
        size="large"
        onClick={showModal}
      >
        Add Certificate
      </Button> */}
      <Modal
        isOpen={addCertificateModalOpen}
        toggle={handleCancel}
        contentClassName="custom-modal-content"
        backdropClassName="custom-modal-backdrop"
      >
        <ModalHeader
          toggle={handleCancel}
          style={{
            fontSize: "18px",
            fontWeight: "bold",
            color: galaxyKids.textOrange,
            backgroundColor: galaxyKids.backgroundColor,
          }}
        >
          Certificate Upload
        </ModalHeader>
        <ModalBody
          className="custom-modal-body"
          style={{
            backgroundColor: galaxyKids.backgroundColor,
          }}
        >
          <Form
            layout="vertical"
            form={form}
            name="certificateUpload"
            onFinish={onFinish}
            style={{
              backgroundColor: galaxyKids.backgroundColor,
            }}
          >
            <Form.Item
              name="certificate"
              label="Certificate"
              rules={[{ required: false, message: "Please select a file!" }]}
            >
              <Upload
                onChange={onChange}
                beforeUpload={beforeUpload}
                fileList={certificateFile ? [certificateFile] : []}
              >
                <Button icon={<UploadOutlined />}>Select File</Button>
              </Upload>

              <span className="text-secondary">Accept Only PDF Input File</span>
            </Form.Item>
          </Form>
        </ModalBody>
        <ModalFooter
          className="custom-modal-footer"
          style={{
            backgroundColor: galaxyKids.backgroundColor,
          }}
        >
          <Button
            className="border-0"
            style={{
              color: galaxyKids.textOrange,
              backgroundColor: galaxyKids.backgroundColor,
            }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            className="border-0"
            style={{ backgroundColor: galaxyKids.textOrange, color: "#fff" }}
            onClick={() => form.submit()}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddCertificate;
