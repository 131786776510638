import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import { Button, Form, Input, Select, message } from "antd";
import {
  UserOutlined,
  AlignLeftOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { galaxyKids } from "../../../assets/styles/globle";

const EditEducation = (props) => {
  const { isModalVisibleEdu, setIsModalVisibleEdu } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchTeacher = async () => {
      const token = localStorage.getItem("accessToken");
      const teacherId = localStorage.getItem("teacherId");
      try {
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/teacher/${teacherId}`
        );
        const educationData = response?.data?.data?.education || [];
        const formattedData = educationData.map((edu, index) => ({
          title: edu.title || "",
          tags: edu.tags || [],
          description: edu.description || "",
        }));
        form.setFieldsValue({ education: formattedData });
      } catch (err) {
        console.log("Error fetching teacher data:", err);
      }
    };
    if (isModalVisibleEdu) {
      fetchTeacher();
    }
  }, [isModalVisibleEdu]);

  const handleCancel = () => {
    setIsModalVisibleEdu(false);
  };

  const handleInstitutionChange = (changedValue, allValues, name) => {
    const education = form.getFieldValue('education');
    if (education[name].tags.length > 1) {
      education[name].tags = [education[name].tags[1]];
      form.setFieldsValue({ education });
      //message.error('You can only select one institution.');
    }
  };

  const onFinish = async (values) => {
    const educationData = values.education || [];
    const token = localStorage.getItem("accessToken");
    const teacherId = localStorage.getItem("teacherId");

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/teacher/basic-details/${teacherId}`,
        { education: educationData },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      message.success("Education details saved successfully!");
      form.resetFields();
      handleCancel();
    } catch (error) {
      console.error("Error saving education details:", error);
      message.error("Failed to save education details. Please try again.");
    }
  };

  return (
    <>
      <Modal
        isOpen={isModalVisibleEdu}
        toggle={handleCancel}
        contentClassName="custom-modal-content"
        backdropClassName="custom-modal-backdrop"
      >
        <ModalHeader
          toggle={handleCancel}
          style={{
            fontSize: "18px",
            fontWeight: "bold",
            color: galaxyKids.textOrange,
            backgroundColor: galaxyKids.backgroundColor,
          }}
        >
          Education Details
        </ModalHeader>
        <ModalBody
          className="custom-modal-body"
          style={{
            backgroundColor: galaxyKids.backgroundColor,
          }}
        >
          <Form
            form={form}
            layout="vertical"
            name="educationForm"
            onFinish={onFinish}
            initialValues={{ education: [{ title: "", tags: [], description: "" }] }}
          >
            <Form.List name="education">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <div key={key}>
                      <Form.Item
                        {...restField}
                        name={[name, "title"]}
                        fieldKey={[fieldKey, "title"]}
                        label="Degree"
                        rules={[{ required: true, message: "Please input the degree!" }]}
                      >
                        <Input
                          prefix={<UserOutlined className="site-form-item-icon" />}
                          placeholder="Degree"
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "tags"]}
                        fieldKey={[fieldKey, "tags"]}
                        label="Institution"
                        rules={[{ required: true, message: "Please input the institution!" }]}
                      >
                        <Select
                            mode="tags"
                            placeholder="Institution"
                            style={{ width: "100%" }}
                            onChange={(value, option) => handleInstitutionChange(value, option, name)}
                        >
                            <Select.Option value="Ava Swift">Ava Swift</Select.Option>
                            <Select.Option value="Cole Reed">Cole Reed</Select.Option>
                            <Select.Option value="Mia Blake">Mia Blake</Select.Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "description"]}
                        fieldKey={[fieldKey, "description"]}
                        label="Description"
                        rules={[{ required: true, message: "Please input description!" }]}
                      >
                        <Input.TextArea
                          prefix={<AlignLeftOutlined className="site-form-item-icon" />}
                          placeholder="Description"
                          rows={4}
                        />
                      </Form.Item>
                      {fields.length > 1 && (
                        <Button
                          type="danger"
                          onClick={() => remove(name)}
                          style={{ float: "right" }}
                          icon={<DeleteOutlined />}
                        >
                          Delete
                        </Button>
                      )}
                    </div>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      style={{ width: "100%", marginBottom: "10px" }}
                    >
                      Add Education
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form>
        </ModalBody>
        <ModalFooter
          className="custom-modal-footer"
          style={{
            backgroundColor: galaxyKids.backgroundColor,
          }}
        >
          <Button
            className="border-0"
            style={{
              color: galaxyKids.textOrange,
              backgroundColor: galaxyKids.backgroundColor,
            }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            className="border-0"
            style={{ backgroundColor: galaxyKids.textOrange, color: "#fff" }}
            onClick={() => form.submit()}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EditEducation;
