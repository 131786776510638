import React, { useEffect, useState } from "react";
import { Form, Input, Button, Divider, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "../Header";
import { galaxyKids } from "../../assets/styles/globle";
import Footer from "../FooterPart";

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    const { email } = values;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/teacher/forgot-password/send-email`,
        { email }
      );
      localStorage.setItem("email", response?.data?.data?.email);
      message.success("Password reset link sent to your email.");
      navigate("/login");
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <div className="container-fluid">
      <Header />
      <div
        className="row no-gutter"
        style={{ backgroundColor: galaxyKids.backgroundColor }}
      >
        <div className="col-md-6 d-none d-md-flex bg-image">
          <img
            src={require("../../assets/images/register.png")}
            alt="Background"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>
        <div className="col-md-6 py-4">
          <div className="login d-flex align-items-center justify-content-center py-4">
            <div className="container py-4">
              <div className="row py-4">
                <div className="col-lg-10 mx-auto py-4">
                  <h2
                    style={{
                      color: galaxyKids.textOrange,
                      fontWeight: "500",
                      textAlign: "center",
                      fontSize: "32px",
                    }}
                    className="py-4"
                  >
                    Forgot Password
                  </h2>
                  {/* <p className="text-secondary">
                    Please enter the email that you gave when you registered. We
                    will sent email to this address with instructions to Forgot
                    your password.
                  </p> */}
                  <Form
                    form={form}
                    onFinish={handleSubmit}
                    initialValues={{ email: "" }}
                  >
                    <Form.Item
                      name="email"
                      rules={[
                        { required: true, message: "Please enter your email" },
                        { type: "email", message: "Invalid email format" },
                      ]}
                    >
                      <Input
                        className="shadow p-3 bg-white rounded"
                        type="email"
                        placeholder="Enter Your Email"
                      />
                    </Form.Item>
                    <div className="d-flex justify-content-center">
                      <Button
                        className="shadow p-3 rounded"
                        style={{
                          backgroundColor: galaxyKids.textOrange,
                          border: "none",
                          width: "50%",
                          height: "50px",
                          color: "#ddd",
                        }}
                        htmlType="submit"
                      >
                        Send Reset Link
                      </Button>
                    </div>
                    <p className="mt-3 text-center">
                      Remember your password?{" "}
                      <span style={{ color: galaxyKids.textColor }}>
                        <Link to={"/login"} style={{ textDecoration: "none" }}>
                          Login
                        </Link>
                      </span>
                    </p>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ForgotPasswordPage;
