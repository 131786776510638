import React from 'react'
import Header from '../common/common/Header'
import { Outlet, useLocation } from 'react-router-dom'

export default function Layout() {
    const location = useLocation();
    const currentPath = location.pathname;
    return (
        <>
            { ['/login',
                '/profile',
                '/dashboard',
                '/profileinformation',
                '/supplemental',
                '/success',
                '/register',
                '/forgotpassword',
                '/resetpassword',
                '/quiz'
                ].includes(currentPath) == false && <Header /> }
            <main>
                <Outlet />
            </main>
        </>
    )
}
