import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Protected(props) {
  const { children } = props;
  const navigate = useNavigate();

  useEffect(() => {
    let accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      navigate("/login");
    }
  }, [navigate]);

  return <div>{children}</div>;
}

export default Protected;
