import React, { useEffect, useState, useRef } from "react";
import { Link } from 'react-router-dom'
import { tutorImg1, tutorImg2, tutorImg3, tutorIntro, tutorIntroPlaceholder } from '../../assets/images'
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  ListGroup,
  ListGroupItem
} from "reactstrap";
import moment from 'moment';

const TutorPage = () => {

  const navigate = useNavigate();
  const [swalProps, setSwalProps] = useState({});
  const { id, tab } = useParams(); // Retrieve id from URL
  const [teacher, setTeacher] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('');
  const [reviews, setReviews] = useState(null);
    
  useEffect(() => {
    const fetchTeacher = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem("accessToken");
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/teacher/${id}`,
          { headers }
        );

        if (response?.data?.data) {
          setTeacher(response.data.data);
        } else {
          setSwalProps({
            show: true,
            title: 'Basic Usage',
            text: 'Hello World',
            confirmButtonText: 'OK', // Customize button text
            showCancelButton: false, // Hide cancel button
          });
        }
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
        setSwalProps({
          icon: 'error',
          show: true,
          //title: '',
          text: 'Teacher Details Not Found ',
          confirmButtonText: 'Go To Home', // Customize button text
          showCancelButton: false, // Hide cancel button
        });
      }
    };

    fetchTeacher();
  }, [id]);

    useEffect(() => { 
        if (tab === 'review' || activeTab === 'review') { // Call the API for reviews when tab is 'review' 
            fetchReviews(); 
        } 
    }, [tab, activeTab, teacher]);

    const fetchReviews = async () => { 
        if(teacher && teacher._id){
            const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/review/user/${teacher._id}`,
            { Authorization: `Bearer ${localStorage.getItem("accessToken")}`}
            );
            if (response?.data?.data) {
                setReviews(response.data.data);
            }
        }
    };

  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const handleTogglePlay = () => {
      if (videoRef.current) {
          if (isPlaying) {
              videoRef.current.pause();
              setIsPlaying(false);
          } else {
              videoRef.current.play();
              setIsPlaying(true);
          }
      }
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
    
  
  
  return (
    <div className="container">
        <div className='tutor-profile-page'>
            <div className='breadcrumb my-3'>
                <Link to="/" className='d-inline-block me-1'>Tutor</Link>
                <span className='fw-medium'>/ {teacher?.first_name} {teacher?.last_name}</span>
            </div>
            <div className='tutor-profile border'>
                <div className='tutor-profile-top border-bottom'>
                    <div className='d-flex align-items-start justify-content-between gap-3 flex-md-row flex-column'>
                        <div className='d-flex gap-3 flex-lg-row flex-column'>
                            <img src={`${process.env.REACT_APP_MEDIA_URL}/user/image/` + teacher?.picture} alt='tutor' width={160} height={160} className='tutor-img object-fit-cover rounded-circle' />
                            <div className='ps-lg-1'>
                                <h1 className='title mb-1 fw-semibold'>{teacher?.first_name} {teacher?.last_name}</h1>
                                <h2 className='subtitle mb-3 fw-medium'> </h2> {/* we dont have the text */}
                                <div className='rating-review mb-3 d-flex text-secondary'>
                                    <div className='d-flex me-1'>
                                        <svg width="16" height="16" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.84841 9.33333C3.91258 9.0475 3.79591 8.63916 3.59175 8.435L2.17425 7.0175C1.73091 6.57416 1.55591 6.10166 1.68425 5.69333C1.81841 5.285 2.23258 5.005 2.85091 4.9L4.67091 4.59666C4.93341 4.55 5.25425 4.31666 5.37675 4.0775L6.38008 2.065C6.67175 1.4875 7.06841 1.16666 7.50008 1.16666C7.93175 1.16666 8.32841 1.4875 8.62008 2.065L9.62341 4.0775C9.69925 4.22916 9.85675 4.375 10.0259 4.47416L3.74341 10.7567C3.66175 10.8383 3.52175 10.7625 3.54508 10.6458L3.84841 9.33333Z" fill="#AE8723" />
                                            <path d="M11.4084 8.435C11.1984 8.645 11.0818 9.0475 11.1518 9.33333L11.5543 11.0892C11.7234 11.8183 11.6184 12.3667 11.2568 12.6292C11.1109 12.7342 10.9359 12.7867 10.7318 12.7867C10.4343 12.7867 10.0843 12.6758 9.69926 12.4483L7.99009 11.4333C7.72176 11.2758 7.27842 11.2758 7.01009 11.4333L5.30092 12.4483C4.65342 12.8275 4.09926 12.8917 3.74342 12.6292C3.60926 12.53 3.51009 12.3958 3.44592 12.2208L10.5393 5.1275C10.8076 4.85916 11.1868 4.73666 11.5543 4.80083L12.1434 4.9C12.7618 5.005 13.1759 5.285 13.3101 5.69333C13.4384 6.10166 13.2634 6.57416 12.8201 7.0175L11.4084 8.435Z" fill="#AE8723" />
                                        </svg>
                                        <svg width="16" height="16" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.84841 9.33333C3.91258 9.0475 3.79591 8.63916 3.59175 8.435L2.17425 7.0175C1.73091 6.57416 1.55591 6.10166 1.68425 5.69333C1.81841 5.285 2.23258 5.005 2.85091 4.9L4.67091 4.59666C4.93341 4.55 5.25425 4.31666 5.37675 4.0775L6.38008 2.065C6.67175 1.4875 7.06841 1.16666 7.50008 1.16666C7.93175 1.16666 8.32841 1.4875 8.62008 2.065L9.62341 4.0775C9.69925 4.22916 9.85675 4.375 10.0259 4.47416L3.74341 10.7567C3.66175 10.8383 3.52175 10.7625 3.54508 10.6458L3.84841 9.33333Z" fill="#AE8723" />
                                            <path d="M11.4084 8.435C11.1984 8.645 11.0818 9.0475 11.1518 9.33333L11.5543 11.0892C11.7234 11.8183 11.6184 12.3667 11.2568 12.6292C11.1109 12.7342 10.9359 12.7867 10.7318 12.7867C10.4343 12.7867 10.0843 12.6758 9.69926 12.4483L7.99009 11.4333C7.72176 11.2758 7.27842 11.2758 7.01009 11.4333L5.30092 12.4483C4.65342 12.8275 4.09926 12.8917 3.74342 12.6292C3.60926 12.53 3.51009 12.3958 3.44592 12.2208L10.5393 5.1275C10.8076 4.85916 11.1868 4.73666 11.5543 4.80083L12.1434 4.9C12.7618 5.005 13.1759 5.285 13.3101 5.69333C13.4384 6.10166 13.2634 6.57416 12.8201 7.0175L11.4084 8.435Z" fill="#AE8723" />
                                        </svg>
                                        <svg width="16" height="16" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.84841 9.33333C3.91258 9.0475 3.79591 8.63916 3.59175 8.435L2.17425 7.0175C1.73091 6.57416 1.55591 6.10166 1.68425 5.69333C1.81841 5.285 2.23258 5.005 2.85091 4.9L4.67091 4.59666C4.93341 4.55 5.25425 4.31666 5.37675 4.0775L6.38008 2.065C6.67175 1.4875 7.06841 1.16666 7.50008 1.16666C7.93175 1.16666 8.32841 1.4875 8.62008 2.065L9.62341 4.0775C9.69925 4.22916 9.85675 4.375 10.0259 4.47416L3.74341 10.7567C3.66175 10.8383 3.52175 10.7625 3.54508 10.6458L3.84841 9.33333Z" fill="#AE8723" />
                                            <path d="M11.4084 8.435C11.1984 8.645 11.0818 9.0475 11.1518 9.33333L11.5543 11.0892C11.7234 11.8183 11.6184 12.3667 11.2568 12.6292C11.1109 12.7342 10.9359 12.7867 10.7318 12.7867C10.4343 12.7867 10.0843 12.6758 9.69926 12.4483L7.99009 11.4333C7.72176 11.2758 7.27842 11.2758 7.01009 11.4333L5.30092 12.4483C4.65342 12.8275 4.09926 12.8917 3.74342 12.6292C3.60926 12.53 3.51009 12.3958 3.44592 12.2208L10.5393 5.1275C10.8076 4.85916 11.1868 4.73666 11.5543 4.80083L12.1434 4.9C12.7618 5.005 13.1759 5.285 13.3101 5.69333C13.4384 6.10166 13.2634 6.57416 12.8201 7.0175L11.4084 8.435Z" fill="#AE8723" />
                                        </svg>
                                        <svg width="16" height="16" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.84841 9.33333C3.91258 9.0475 3.79591 8.63916 3.59175 8.435L2.17425 7.0175C1.73091 6.57416 1.55591 6.10166 1.68425 5.69333C1.81841 5.285 2.23258 5.005 2.85091 4.9L4.67091 4.59666C4.93341 4.55 5.25425 4.31666 5.37675 4.0775L6.38008 2.065C6.67175 1.4875 7.06841 1.16666 7.50008 1.16666C7.93175 1.16666 8.32841 1.4875 8.62008 2.065L9.62341 4.0775C9.69925 4.22916 9.85675 4.375 10.0259 4.47416L3.74341 10.7567C3.66175 10.8383 3.52175 10.7625 3.54508 10.6458L3.84841 9.33333Z" fill="#AE8723" />
                                            <path d="M11.4084 8.435C11.1984 8.645 11.0818 9.0475 11.1518 9.33333L11.5543 11.0892C11.7234 11.8183 11.6184 12.3667 11.2568 12.6292C11.1109 12.7342 10.9359 12.7867 10.7318 12.7867C10.4343 12.7867 10.0843 12.6758 9.69926 12.4483L7.99009 11.4333C7.72176 11.2758 7.27842 11.2758 7.01009 11.4333L5.30092 12.4483C4.65342 12.8275 4.09926 12.8917 3.74342 12.6292C3.60926 12.53 3.51009 12.3958 3.44592 12.2208L10.5393 5.1275C10.8076 4.85916 11.1868 4.73666 11.5543 4.80083L12.1434 4.9C12.7618 5.005 13.1759 5.285 13.3101 5.69333C13.4384 6.10166 13.2634 6.57416 12.8201 7.0175L11.4084 8.435Z" fill="#AE8723" />
                                        </svg>
                                        <svg width="16" height="16" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.84841 9.33333C3.91258 9.0475 3.79591 8.63916 3.59175 8.435L2.17425 7.0175C1.73091 6.57416 1.55591 6.10166 1.68425 5.69333C1.81841 5.285 2.23258 5.005 2.85091 4.9L4.67091 4.59666C4.93341 4.55 5.25425 4.31666 5.37675 4.0775L6.38008 2.065C6.67175 1.4875 7.06841 1.16666 7.50008 1.16666C7.93175 1.16666 8.32841 1.4875 8.62008 2.065L9.62341 4.0775C9.69925 4.22916 9.85675 4.375 10.0259 4.47416L3.74341 10.7567C3.66175 10.8383 3.52175 10.7625 3.54508 10.6458L3.84841 9.33333Z" fill="#AE8723" />
                                            <path d="M11.4084 8.435C11.1984 8.645 11.0818 9.0475 11.1518 9.33333L11.5543 11.0892C11.7234 11.8183 11.6184 12.3667 11.2568 12.6292C11.1109 12.7342 10.9359 12.7867 10.7318 12.7867C10.4343 12.7867 10.0843 12.6758 9.69926 12.4483L7.99009 11.4333C7.72176 11.2758 7.27842 11.2758 7.01009 11.4333L5.30092 12.4483C4.65342 12.8275 4.09926 12.8917 3.74342 12.6292C3.60926 12.53 3.51009 12.3958 3.44592 12.2208L10.5393 5.1275C10.8076 4.85916 11.1868 4.73666 11.5543 4.80083L12.1434 4.9C12.7618 5.005 13.1759 5.285 13.3101 5.69333C13.4384 6.10166 13.2634 6.57416 12.8201 7.0175L11.4084 8.435Z" fill="#AE8723" />
                                        </svg>
                                    </div>
                                    <span className='d-inline-block pe-2 me-2 border-end fw-semibold'></span>
                                    <span className='text-decoration-underline'>230 Review</span> {/* currently we dont have reivew */}
                                </div>
                                <div className='location d-flex'>
                                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.49992 8.95334C9.64867 8.95334 10.5799 8.02209 10.5799 6.87334C10.5799 5.72458 9.64867 4.79333 8.49992 4.79333C7.35117 4.79333 6.41992 5.72458 6.41992 6.87334C6.41992 8.02209 7.35117 8.95334 8.49992 8.95334Z" stroke="#101820" />
                                        <path d="M2.91349 5.65998C4.22682 -0.113352 12.7802 -0.106685 14.0868 5.66665C14.8535 9.05331 12.7468 11.92 10.9002 13.6933C9.56015 14.9866 7.44015 14.9866 6.09349 13.6933C4.25349 11.92 2.14682 9.04665 2.91349 5.65998Z" stroke="#101820" />
                                    </svg>
                                    <span className='d-inline-block ms-2 fw-medium'>{[
                                            teacher?.address?.street,
                                            teacher?.address?.city,
                                            teacher?.address?.state,
                                            teacher?.address?.postcode,
                                            teacher?.address?.country
                                            ].filter(Boolean).join(', ')}</span>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flex-column gap-3 align-items-md-end'>
                            <button className='btn btn-primary'>Contact Now</button>
                            <span className='rate d-inline-block pt-md-1'>
                                <span className='fw-semibold'>{ teacher?.pricing }</span>
                                <span className='text-secondary fw-normal'>/hr</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div className='tutor-profile-bottom d-flex align-items-center justify-content-evenly'>
                    <div className='d-flex align-items-start gap-2'>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.3332 9.99999C18.3332 14.6 14.5998 18.3333 9.99984 18.3333C5.39984 18.3333 1.6665 14.6 1.6665 9.99999C1.6665 5.39999 5.39984 1.66666 9.99984 1.66666C14.5998 1.66666 18.3332 5.39999 18.3332 9.99999Z" stroke="#070A14" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M13.0914 12.65L10.5081 11.1083C10.0581 10.8417 9.69141 10.2 9.69141 9.675V6.25833" stroke="#070A14" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <div>
                            <div className='label mb-2 fw-medium'>Availability</div>
                            <div className='value fw-semibold'>
                            {teacher?.availability?.map((availability, index) => (
                              <ListGroup flush key={index}>
                                <ListGroupItem>{availability}</ListGroupItem>
                              </ListGroup>
                            ))}
                            </div>
                        </div>
                    </div>
                    <div className='divider'></div>
                    <div className='d-flex align-items-start gap-2'>
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.6663 18.3333C15.2687 18.3333 18.9997 14.6024 18.9997 9.99999C18.9997 5.39762 15.2687 1.66666 10.6663 1.66666C6.06397 1.66666 2.33301 5.39762 2.33301 9.99999C2.33301 14.6024 6.06397 18.3333 10.6663 18.3333Z" stroke="#101820" strokeWidth="0.833333" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M7.33317 2.5H8.1665C6.5415 7.36667 6.5415 12.6333 8.1665 17.5H7.33317" stroke="#101820" strokeWidth="0.833333" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M13.1665 2.5C14.7915 7.36667 14.7915 12.6333 13.1665 17.5" stroke="#101820" strokeWidth="0.833333" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M3.1665 13.3333V12.5C8.03317 14.125 13.2998 14.125 18.1665 12.5V13.3333" stroke="#101820" strokeWidth="0.833333" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M3.1665 7.5C8.03317 5.875 13.2998 5.875 18.1665 7.5" stroke="#101820" strokeWidth="0.833333" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <div>
                            <div className='label mb-2 fw-medium'>Languages</div>
                            <div className='value fw-semibold'>
                            { teacher?.language_skills?.map(item => capitalizeFirstLetter(item.language)).join(', ') }
                            </div>
                        </div>
                    </div>
                    <div className='divider'></div>
                    <div className='d-flex align-items-start gap-2'>
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.3337 9.99999C12.6348 9.99999 14.5003 8.13451 14.5003 5.83332C14.5003 3.53214 12.6348 1.66666 10.3337 1.66666C8.03247 1.66666 6.16699 3.53214 6.16699 5.83332C6.16699 8.13451 8.03247 9.99999 10.3337 9.99999Z" stroke="#101820" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M3.17529 18.3333C3.17529 15.1083 6.38362 12.5 10.3336 12.5C11.1336 12.5 11.9086 12.6083 12.6336 12.8083" stroke="#101820" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M18.6667 15C18.6667 15.625 18.4917 16.2167 18.1833 16.7167C18.0083 17.0167 17.7833 17.2833 17.525 17.5C16.9417 18.025 16.175 18.3333 15.3333 18.3333C14.1167 18.3333 13.0583 17.6833 12.4833 16.7167C12.175 16.2167 12 15.625 12 15C12 13.95 12.4833 13.0083 13.25 12.4C13.825 11.9417 14.55 11.6667 15.3333 11.6667C17.175 11.6667 18.6667 13.1583 18.6667 15Z" stroke="#101820" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M14.0332 15L14.8582 15.825L16.6332 14.1833" stroke="#101820" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <div>
                            <div className='label mb-2 fw-medium'>Expertise</div>
                            <div className='value fw-semibold'>{ teacher?.teaching_experience?.filter(Boolean).join(', ') } </div>
                        </div>
                    </div>
                    <div className='divider'></div>
                    <div className='d-flex align-items-start gap-2'>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.6665 1.66666V4.16666" stroke="#101820" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M13.3335 1.66666V4.16666" stroke="#101820" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M2.9165 7.57501H17.0832" stroke="#101820" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M17.5 7.08332V14.1667C17.5 16.6667 16.25 18.3333 13.3333 18.3333H6.66667C3.75 18.3333 2.5 16.6667 2.5 14.1667V7.08332C2.5 4.58332 3.75 2.91666 6.66667 2.91666H13.3333C16.25 2.91666 17.5 4.58332 17.5 7.08332Z" stroke="#101820" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M9.99607 11.4167H10.0036" stroke="#101820" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M6.91209 11.4167H6.91957" stroke="#101820" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M6.91209 13.9167H6.91957" stroke="#101820" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <div>
                            <div className='label mb-2 fw-medium'>Joined</div>
                            <div className='value fw-semibold'>{ new Date(teacher?.createdAt).getFullYear() }</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='tutor-info'>
                <ul className="nav nav-tabs border-0 mb-4 pb-md-2" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button onClick={() => setActiveTab('')} className={`nav-link ${tab != 'review' ? "active" : ""}`} id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">
                            Introduction
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button onClick={() => setActiveTab('review')} className={`nav-link ${tab === 'review' ? "active" : ""}`} id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">
                            Reviews
                        </button>
                    </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                
                    <div className={`tab-pane fade intro-box ${tab != 'review' ? "show active" : ""}`} id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                        <h3 className='title mb-md-3 pb-md-1 fw-semibold'>About</h3>
                        <p className='content fw-medium mb-md-4 pb-md-3'>
                            Passionate and experienced tutor with a strong background in helping students achieve their academic goals. Skilled in breaking down complex concepts and tailoring lessons to individual learning styles, I focus on creating an encouraging, interactive environment to boost both understanding and confidence. With expertise in [subject area(s)], I am committed to helping each student reach their full potential through personalized guidance, structured lessons, and a positive approach to challenges. Whether preparing for exams or mastering new skills, I am dedicated to supporting my students' success every step of the way
                        </p>
                        <div className={`intro-video position-relative d-flex ${isPlaying ? 'playing' : ''}`}>
                            <video
                                ref={videoRef}
                                poster={tutorIntroPlaceholder}
                                className='w-100'
                                onClick={handleTogglePlay}
                                onPlay={() => setIsPlaying(true)}
                                onPause={() => setIsPlaying(false)}
                            >
                                <source src={ `${process.env.REACT_APP_MEDIA_URL}/user/video/` + teacher?.intro_video } type="video/mp4" />
                            </video>
                            {!isPlaying && (
                                <button
                                    onClick={handleTogglePlay}
                                    className='play-btn bg-transparent p-0 border-0 position-absolute top-50 start-50 translate-middle z-1'
                                >
                                    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M31.9206 5.33337C17.2006 5.33337 5.25391 17.28 5.25391 32C5.25391 46.72 17.2006 58.6667 31.9206 58.6667C46.6406 58.6667 58.5872 46.72 58.5872 32C58.5872 17.28 46.6672 5.33337 31.9206 5.33337ZM39.9206 37.9467L32.1872 42.4C31.2272 42.96 30.1606 43.2267 29.1206 43.2267C28.0539 43.2267 27.0139 42.96 26.0539 42.4C24.1339 41.28 22.9872 39.3067 22.9872 37.0667V28.1334C22.9872 25.92 24.1339 23.92 26.0539 22.8C27.9739 21.68 30.2672 21.68 32.2139 22.8L39.9472 27.2534C41.8672 28.3734 43.0139 30.3467 43.0139 32.5867C43.0139 34.8267 41.8672 36.8267 39.9206 37.9467Z" fill="white" />
                                    </svg>
                                </button>
                            )}
                        </div>
                    </div>
                    
                    
                    <div className={`tab-pane fade review-box ${tab === 'review' ? "show active" : ""}`} id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="0">
                        <div className='row g-4'>
                            { reviews && reviews.map((item, key) => (
                                <div key={key} className='col-md-6 review-col d-flex flex-column'>
                                    <div className='review-item d-flex'>
                                        {/* <img src={tutorImg2} alt='user' width={40} height={40} className='user-avatar object-fit-cover rounded-circle' /> */}
                                        <span className='user-avatar rounded-circle d-flex align-items-center justify-content-center fw-medium'>{`${capitalizeFirstLetter(item?.reviewer_id?.first_name)} ${capitalizeFirstLetter(item?.reviewer_id?.last_name)}`.split(' ').map(word => word[0]).join('')}</span>
                                        <div className='flex-grow-1'>
                                            <div className='name d-flex align-items-center gap-2 justify-content-between'>
                                            
                                                <h4 className='fw-semibold mb-0'>{`${capitalizeFirstLetter(item?.reviewer_id?.first_name)} ${capitalizeFirstLetter(item?.reviewer_id?.last_name)}`}</h4>
                                                <small>{moment(item?.createdAt).format('DD MMM YYYY')}</small>
                                            </div>
                                            <div className='review d-flex align-items-center gap-1'>
                                                <div className='d-flex gap-1'>
                                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M11.9686 4.53412C11.8896 4.28056 11.6741 4.10105 11.4201 4.07707L7.95606 3.7489L6.58705 0.404315C6.48598 0.158592 6.25599 0 6.00001 0C5.74402 0 5.51395 0.158592 5.41351 0.404315L4.0445 3.7489L0.579911 4.07707C0.32594 4.10152 0.110879 4.28104 0.0314103 4.53412C-0.0476009 4.78768 0.0253677 5.06579 0.217448 5.24157L2.83599 7.63756L2.06391 11.186C2.00742 11.4469 2.10447 11.7167 2.31193 11.8732C2.42345 11.9578 2.55446 12 2.68593 12C2.79891 12 2.91198 11.9687 3.01296 11.9056L6.00001 10.0419L8.9865 11.9056C9.20559 12.0422 9.48108 12.0297 9.68808 11.8732C9.89554 11.7167 9.99259 11.4469 9.9361 11.186L9.16403 7.63756L11.7826 5.24157C11.9746 5.06579 12.0476 4.78825 11.9686 4.53412Z" fill="#AE8723" />
                                                    </svg>
                                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M11.9686 4.53412C11.8896 4.28056 11.6741 4.10105 11.4201 4.07707L7.95606 3.7489L6.58705 0.404315C6.48598 0.158592 6.25599 0 6.00001 0C5.74402 0 5.51395 0.158592 5.41351 0.404315L4.0445 3.7489L0.579911 4.07707C0.32594 4.10152 0.110879 4.28104 0.0314103 4.53412C-0.0476009 4.78768 0.0253677 5.06579 0.217448 5.24157L2.83599 7.63756L2.06391 11.186C2.00742 11.4469 2.10447 11.7167 2.31193 11.8732C2.42345 11.9578 2.55446 12 2.68593 12C2.79891 12 2.91198 11.9687 3.01296 11.9056L6.00001 10.0419L8.9865 11.9056C9.20559 12.0422 9.48108 12.0297 9.68808 11.8732C9.89554 11.7167 9.99259 11.4469 9.9361 11.186L9.16403 7.63756L11.7826 5.24157C11.9746 5.06579 12.0476 4.78825 11.9686 4.53412Z" fill="#AE8723" />
                                                    </svg>
                                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M11.9686 4.53412C11.8896 4.28056 11.6741 4.10105 11.4201 4.07707L7.95606 3.7489L6.58705 0.404315C6.48598 0.158592 6.25599 0 6.00001 0C5.74402 0 5.51395 0.158592 5.41351 0.404315L4.0445 3.7489L0.579911 4.07707C0.32594 4.10152 0.110879 4.28104 0.0314103 4.53412C-0.0476009 4.78768 0.0253677 5.06579 0.217448 5.24157L2.83599 7.63756L2.06391 11.186C2.00742 11.4469 2.10447 11.7167 2.31193 11.8732C2.42345 11.9578 2.55446 12 2.68593 12C2.79891 12 2.91198 11.9687 3.01296 11.9056L6.00001 10.0419L8.9865 11.9056C9.20559 12.0422 9.48108 12.0297 9.68808 11.8732C9.89554 11.7167 9.99259 11.4469 9.9361 11.186L9.16403 7.63756L11.7826 5.24157C11.9746 5.06579 12.0476 4.78825 11.9686 4.53412Z" fill="#AE8723" />
                                                    </svg>
                                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M11.9686 4.53412C11.8896 4.28056 11.6741 4.10105 11.4201 4.07707L7.95606 3.7489L6.58705 0.404315C6.48598 0.158592 6.25599 0 6.00001 0C5.74402 0 5.51395 0.158592 5.41351 0.404315L4.0445 3.7489L0.579911 4.07707C0.32594 4.10152 0.110879 4.28104 0.0314103 4.53412C-0.0476009 4.78768 0.0253677 5.06579 0.217448 5.24157L2.83599 7.63756L2.06391 11.186C2.00742 11.4469 2.10447 11.7167 2.31193 11.8732C2.42345 11.9578 2.55446 12 2.68593 12C2.79891 12 2.91198 11.9687 3.01296 11.9056L6.00001 10.0419L8.9865 11.9056C9.20559 12.0422 9.48108 12.0297 9.68808 11.8732C9.89554 11.7167 9.99259 11.4469 9.9361 11.186L9.16403 7.63756L11.7826 5.24157C11.9746 5.06579 12.0476 4.78825 11.9686 4.53412Z" fill="#AE8723" />
                                                    </svg>
                                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M11.9686 4.53412C11.8896 4.28056 11.6741 4.10105 11.4201 4.07707L7.95606 3.7489L6.58705 0.404315C6.48598 0.158592 6.25599 0 6.00001 0C5.74402 0 5.51395 0.158592 5.41351 0.404315L4.0445 3.7489L0.579911 4.07707C0.32594 4.10152 0.110879 4.28104 0.0314103 4.53412C-0.0476009 4.78768 0.0253677 5.06579 0.217448 5.24157L2.83599 7.63756L2.06391 11.186C2.00742 11.4469 2.10447 11.7167 2.31193 11.8732C2.42345 11.9578 2.55446 12 2.68593 12C2.79891 12 2.91198 11.9687 3.01296 11.9056L6.00001 10.0419L8.9865 11.9056C9.20559 12.0422 9.48108 12.0297 9.68808 11.8732C9.89554 11.7167 9.99259 11.4469 9.9361 11.186L9.16403 7.63756L11.7826 5.24157C11.9746 5.06579 12.0476 4.78825 11.9686 4.53412Z" fill="#AE8723" />
                                                    </svg>
                                                </div>
                                                <div className='fw-medium d-flex gap-1'>
                                                    <span>{item?.rating}</span>
                                                    <span>|</span>
                                                    <span>{capitalizeFirstLetter(item?.ratingLabel)}</span>
                                                </div>
                                            </div>
                                            { item?.comment && <p className='mb-0 mt-2 content'>{item?.comment}</p> }
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default TutorPage;
