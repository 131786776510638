import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { UserOutlined, DollarOutlined } from "@ant-design/icons";
import { Button, DatePicker, Form, Input, Upload, message, Select } from "antd";
import axios from "axios";
import { galaxyKids } from "../../../assets/styles/globle";
import dayjs from "dayjs";
import { useLoadScript } from "@react-google-maps/api";
import AsyncSelect from "react-select/async";
import { useSettings } from '../../../context/CountryContext';

const libraries = ["places"];

const EditBasicInfo = (props) => {
  const {
    isModalVisibleBasic,
    setIsModalVisibleBasic,
    imageFile,
    setImageFile,
    setPicture,
  } = props;
  const { configuredSettings, load } = useSettings();
  const experienceOptions = [];
  configuredSettings?.data?.experience.map((data) => {
    experienceOptions.push({ value: data, label: data });
  })
  const [teacher, setTeacher] = useState(null);
  const [initialTeacher, setInitialTeacher] = useState(null); // Store initial teacher data
  const [loading, setLoading] = useState(false);

  const [coordinates, setCoordinates] = useState({ lng: null, lat: null });
  const [address, setAddress] = useState({});

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries,
  });

  const handleCancel = () => {
    setIsModalVisibleBasic(false);
  };

  useEffect(() => {
    const teacherId = localStorage.getItem("teacherId");
    const fetchTeacher = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/teacher/${teacherId}`
        );
        const fetchedTeacher = response?.data?.data;
        setTeacher(fetchedTeacher);
        setInitialTeacher(fetchedTeacher); // Store initial data
      } catch (err) {
        console.log("Error fetching teacher data:", err);
      }
    };

    fetchTeacher();

    const fetchCountries = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/countries`);
        setCountries(response.data.data);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();

  }, []);

  const fetchStates = async (countryId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/states/${countryId}`);
      setStates(response.data.data);
      setCities([]); // Clear cities when country changes
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  const fetchCities = async (stateId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/cities/${stateId}`);
      setCities(response.data.data);
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  const handleCountryChange = (option) => {
    setSelectedCountry(option);
    fetchStates(typeof option ? option : option.value);
    setSelectedState(null);
    setSelectedCity(null);
  };

  const handleStateChange = (option) => {
    setSelectedState(option);
    fetchCities(typeof option ? option : option.value);
    setSelectedCity(null);
  };

  const handleCityChange = (option) => {
    console.log("selectedCity >> >> ",option);
    setSelectedCity(option);
  };

  const [form] = Form.useForm();

  useEffect(() => {
    if (teacher) {
      const addressParts = [
        teacher?.address?.street,
        teacher?.address?.city,
        teacher?.address?.state,
        teacher?.address?.postcode,
        teacher?.address?.country
      ].filter(Boolean).join(', ');

      form.setFieldsValue({
        first_name: teacher?.first_name,
        last_name: teacher?.last_name,
        company_name: teacher?.company_name,
        teaching_experience: teacher?.teaching_experience,
        pricing: Number(teacher?.pricing),
        city: teacher?.address?.city,
        state: teacher?.address?.state,
        country: teacher?.address?.country,
        location: {
          label: `${ addressParts }`,
          value: teacher.location.coordinates,
        },
        birthday: teacher.date_of_birth ? dayjs(teacher.date_of_birth, "DD/MM/YYYY") : null,
        gender: teacher.gender,
      });


      console.log( teacher?.address?.city, teacher?.address?.state, teacher?.address?.country )

      let getSelectedContries = countries.filter( country => country.name == teacher?.address?.country );
      if(getSelectedContries && getSelectedContries[0]) {
          handleCountryChange(`${getSelectedContries[0].id}/${getSelectedContries[0].iso2}`);
      }

      //`${state.id}/${state.state_code}`
      let getSelectedState = states.filter( state => state.name == teacher?.address?.state );
      if(getSelectedState && getSelectedState[0]) {
        handleStateChange(`${getSelectedState[0].id}/${getSelectedState[0].state_code}`);
      }
    }
  }, [teacher, countries]);

  const onFinish = async (values) => {
    setLoading(true);
    const specificDate = new Date(values.birthday);
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const formattedDateOfBirth = specificDate.toLocaleDateString("en-GB", options);
    const token = localStorage.getItem("accessToken");
    const teacherId = localStorage.getItem("teacherId");
  
    const updatedData = {};
  
    // Compare each field and only include changed fields
    if (values.first_name !== initialTeacher.first_name) {
      updatedData.first_name = values.first_name;
    }
    if (values.last_name !== initialTeacher.last_name) {
      updatedData.last_name = values.last_name;
    }
    if (values.company_name !== initialTeacher.company_name) {
      updatedData.company_name = values.company_name;
    }
    if (values.teaching_experience.toString() !== initialTeacher.teaching_experience.toString()) {
      updatedData.teaching_experience = values.teaching_experience;
    }
    if (values.pricing !== initialTeacher.pricing) {
      updatedData.pricing = Number(values.pricing);
    }
    if (formattedDateOfBirth !== initialTeacher.date_of_birth) {
      updatedData.date_of_birth = formattedDateOfBirth;
    }
    if (values.gender !== initialTeacher.gender) {
      updatedData.gender = values.gender;
    }
    
    updatedData.address = {
      city:  selectedCity ? selectedCity : teacher?.address?.city,
      state: teacher?.address?.state,
      country: teacher?.address?.country
    }
    
    let getCityDetails = cities.filter( city => city.name == (selectedCity ? selectedCity : teacher?.address?.city) );
    if(getCityDetails && getCityDetails[0]) {
      const formattedCoordinates = [
        parseFloat(getCityDetails[0].longitude).toFixed(4),
        parseFloat(getCityDetails[0].latitude).toFixed(4),
      ];
      updatedData.location = { coordinates: formattedCoordinates, type: "Point" };
    }

    // if (typeof values.location.value === 'object') {
    //   const [longitude, latitude] = values.location.value;
    //   if (
    //     longitude !== initialTeacher.location.coordinates[0] ||
    //     latitude !== initialTeacher.location.coordinates[1]
    //   ) {
    //     updatedData.location = { coordinates: [longitude, latitude], type: "Point" };
    //   }
    // } else if (coordinates && coordinates.lng && coordinates.lat) {
    //   const lng = parseFloat(coordinates.lng).toFixed(4);
    //   const lat = parseFloat(coordinates.lat).toFixed(4);
    //   if (
    //     lng !== initialTeacher.location.coordinates[0] ||
    //     lat !== initialTeacher.location.coordinates[1]
    //   ) {
    //     updatedData.location = { coordinates: [lng, lat], type: "Point" };
    //   }
    // }
  
    
    // Check if there's anything to update
    if (Object.keys(updatedData).length === 0) {
      message.info("No changes to update.");
      setLoading(false);
      return;
    }
  
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/teacher/update-details/${teacherId}`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        message.success("Form submitted successfully");
        setIsModalVisibleBasic(false);
      } else {
        message.error(response.data.message || "Something went wrong");
      }
    } catch (error) {
      console.error("Error:", error);
      message.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleUpload = ({ file }) => {
    const fileNameShortened = file.name.substring(0, 10);
    const formData = new FormData();
    const token = localStorage.getItem("accessToken");
    const teacherId = localStorage.getItem("teacherId");
    formData.append("picture", file);
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/teacher/update-details/${teacherId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        message.success(`${fileNameShortened} uploaded successfully.`);
        setImageFile(file);
        setPicture(file);
      })
      .catch((error) => {
        console.log(error);
        //message.error(`${fileNameShortened} upload failed.`);
      });
  };

  const fetchPlaceSuggestions = async (inputValue) => {
    if (!isLoaded) return [];

    const autocompleteService = new window.google.maps.places.AutocompleteService();
    return new Promise((resolve, reject) => {
      autocompleteService.getPlacePredictions({ input: inputValue }, (predictions, status) => {
        if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
          reject(status);
          return;
        }
        resolve(
          predictions.map((prediction) => ({
            label: prediction.description,
            value: prediction.place_id,
          }))
        );
      });
    });
  };

  // const handlePlaceSelect = (selectedOption) => {
  //   const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
  //   placesService.getDetails({ placeId: selectedOption.value }, (place, status) => {
  //     if (status === window.google.maps.places.PlacesServiceStatus.OK) {
  //       setCoordinates({
  //         lng: place.geometry.location.lng(),
  //         lat: place.geometry.location.lat(),
  //       });

  //       const addressComponents = place.address_components;
        
  //       const formatComponent = (component) => component ? `${component}, ` : '';

  //       const neighborhood = getComponent(addressComponents, 'neighborhood');
  //       const sublocalityLevel2 = getComponent(addressComponents, 'sublocality_level_2');
  //       const sublocalityLevel1 = getComponent(addressComponents, 'sublocality_level_1');
  //       // Remove trailing comma and whitespace if present
  //       const formattedStreetAddress = `${formatComponent(neighborhood)}${formatComponent(sublocalityLevel2)}${sublocalityLevel1}`.trim().replace(/,\s*$/, '');

  //       const address = {
  //         street: formattedStreetAddress,
  //         city: getComponent(addressComponents, 'locality'),
  //         state: getComponent(addressComponents, 'administrative_area_level_1'),
  //         country: getComponent(addressComponents, 'country'),
  //         postcode: getComponent(addressComponents, 'postal_code')
  //       };
  //       setAddress(address);
  //       form.setFieldsValue({
  //         city: getComponent(addressComponents, 'locality'),
  //         country: getComponent(addressComponents, 'country')
  //       });
  //     }
  //   });
  // };

  const getComponent = (components, type) => {
    const component = components.find(component => component.types.includes(type));
    return component ? component.long_name : '';
  };

  if (loadError) return <div>Error loading wait..</div>;
  if (!isLoaded) return (<div>loading wait..</div>);

  return (
    <>
      <Modal
        isOpen={isModalVisibleBasic}
        toggle={handleCancel}
        contentClassName="custom-modal-content"
        backdropClassName="custom-modal-backdrop"
      >
        <ModalHeader
          toggle={handleCancel}
          style={{
            fontSize: "18px",
            fontWeight: "bold",
            color: galaxyKids.textOrange,
            backgroundColor: galaxyKids.backgroundColor,
          }}
        >
          Basic Info
        </ModalHeader>
        <ModalBody
          className="custom-modal-body"
          style={{
            backgroundColor: galaxyKids.backgroundColor,
          }}
        >
          <Form
            layout="vertical"
            form={form}
            name="basic"
            onFinish={onFinish}
            style={{
              backgroundColor: galaxyKids.backgroundColor,
            }}
          >
            <Form.Item
              name="first_name"
              label="First Name"
              rules={[{ required: true, message: "Please input your firstname!" }]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="First Name"
                disabled={loading}
              />
            </Form.Item>
            <Form.Item
              name="last_name"
              label="Last Name"
              rules={[{ required: true, message: "Please input your lastname!" }]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Last Name"
                disabled={loading}
              />
            </Form.Item>
            <Form.Item
              name="company_name"
              label="Company Name"
              rules={[{ required: true, message: "Please input your company name!" }]}
            >
              <Input
                placeholder="Company Name"
                disabled={loading}
              />
            </Form.Item>
            {/* <Form.Item
              name="location"
              label="Location"
              rules={[
                {
                  required: true,
                  message: "Please select your location!",
                },
              ]}
            >
              <AsyncSelect
                cacheOptions
                loadOptions={fetchPlaceSuggestions}
                onChange={handlePlaceSelect}
                placeholder="Search for a location"
                defaultOptions={[]}
              />
            </Form.Item> */}
            {/* <Form.Item
              name="city"
              label="City"
              rules={[{ required: true, message: "Please input your city!" }]}
            >
              <Input placeholder="City" disabled />
            </Form.Item>
            <Form.Item
              name="country"
              label="Country"
              rules={[
                { required: true, message: "Please input your country!" },
              ]}
            >
              <Input placeholder="Country" disabled />
            </Form.Item> */}
            <Form.Item
              label="Country"
              name="country"
              rules={[
                {
                  required: true,
                  message: "Please select your country",
                },
              ]}
            >
              <Select
                showSearch
                value={selectedCountry}
                options={countries.map(country => ({ value: `${country.id}/${country.iso2}`, label: country.name }))}
                placeholder="Select your country"
                onChange={handleCountryChange}
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
              />
            </Form.Item>
            <Form.Item
              label="State"
              name="state"
              rules={[
                {
                  required: true,
                  message: "Please select your state",
                },
              ]}
            >
              <Select
                showSearch
                value={selectedState}
                options={states.map(state => ({ value: `${state.id}/${state.state_code}`, label: state.name }))}
                placeholder="Select your state"
                onChange={handleStateChange}
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
                isDisabled={!selectedCountry}
              />
            </Form.Item>
            <Form.Item
              label="City"
              name="city"
              rules={[
                {
                  required: true,
                  message: "Please select your city",
                },
              ]}
            >
              <Select
                showSearch
                value={selectedCity}
                options={cities.map(city => ({ value: city.name, label: city.name }))}
                placeholder="Select your city"
                onChange={handleCityChange}
                disabled={!selectedState}
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
              />

            </Form.Item>
            <Form.Item
              name="birthday"
              label="Birthday"
              rules={[
                { required: true, message: "Please input your birthday!" },
              ]}
            >
              <DatePicker
                format={"DD/MM/YYYY"}
                style={{
                  width: "100%",
                }}
                placeholder="Birthday"
                showToday={false}
                value={form.getFieldValue("birthday") ? dayjs(form.getFieldValue("birthday")) : null}
              />
            </Form.Item>
            <Form.Item
              name="gender"
              label="Gender"
              rules={[
                { required: true, message: "Please select your gender!" },
              ]}
            >
              <Select placeholder="Select your gender">
                <Select.Option value="Male">Male</Select.Option>
                <Select.Option value="Female">Female</Select.Option>
                <Select.Option value="Prefer not to say">Prefer not to say</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="teaching_experience"
              label="Teaching Experience"
              rules={[
                { required: true, message: "Please select your teaching experience" },
              ]}
            >
              {/* <Select mode="multiple" placeholder="Select your Experience">
                <Select.Option value="Preschool">Preschool</Select.Option>
                <Select.Option value="K12">K12</Select.Option>
                <Select.Option value="Adult">Adult</Select.Option>
              </Select> */}
              <Select
                mode="multiple"
                value={experienceOptions.find(option => option.value === form.getFieldValue('experience'))}
                options={experienceOptions}
                placeholder="Select your Experience"
                onChange={value => form.setFieldsValue({ experience: value })}
              />
            </Form.Item>
            <Form.Item
              name="pricing"
              label="Price Per Hour(USD)"
              rules={[{ required: true, message: "Please enter USD per hour" }]}
            >
              <Input
                prefix={<DollarOutlined className="site-form-item-icon" />}
                placeholder="Price Per Hour(USD)"
                disabled={loading}
              />
            </Form.Item>
            <Form.Item
              name="picture"
              label="Profile Picture"
              valuePropName="fileList"
              getValueFromEvent={(e) => e && e.fileList}
            >
              <Upload
                action=""
                beforeUpload={() => false} // Disable automatic upload
                onChange={handleUpload}
                fileList={imageFile ? [imageFile] : []}
                maxCount={1}
                accept="image/*"
                listType="picture-circle"
                showUploadList={false} // Hide the default file list
              >
                {imageFile ? (
                  <img
                    src={URL.createObjectURL(imageFile)}
                    alt="Uploaded"
                    style={{
                      width: "102px",
                      height: "102px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <div>
                    <img
                      src={
                        `${process.env.REACT_APP_MEDIA_URL}/user/image/` +
                        teacher?.picture
                      }
                      alt="Uploaded"
                      style={{
                        width: "102px",
                        height: "102px",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                )}
              </Upload>
            </Form.Item>
          </Form>
        </ModalBody>
        <ModalFooter
          className="custom-modal-footer"
          style={{
            backgroundColor: galaxyKids.backgroundColor,
          }}
        >
          <Button
            className="border-0"
            style={{
              color: galaxyKids.textOrange,
              backgroundColor: galaxyKids.backgroundColor,
            }}
            onClick={handleCancel}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            className="border-0"
            style={{ backgroundColor: galaxyKids.textOrange, color: "#fff" }}
            onClick={() => form.submit()}
            loading={loading}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EditBasicInfo;
