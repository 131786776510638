import React, { useEffect, useState } from "react";
import { Col, Progress, Row } from "reactstrap";
import Footer from "../FooterPart";
import Header from "../Header";
import { useLocation, useNavigate } from "react-router-dom";
import { galaxyKids } from "../../assets/styles/globle";
import axios from "axios";
import ReactPlayer from "react-player";
import { Button } from "antd";

const Quiz = () => {
  const [quizDetails, setQuizDetails] = useState(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const fetchQuizDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/quiz`
        );
        setQuizDetails(response.data.data);
      } catch (err) {
        console.log("🚀 ~ fetchQuizDetails ~ err:", err);
      }
    };
    fetchQuizDetails();
  }, []);

  const renderMedia = (file) => {
    if (!file) return null;
    const fileExtension = file.split('.').pop().toLowerCase();
    const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension);
    const isVideo = ['mp4', 'avi', 'mov', 'wmv'].includes(fileExtension);

    if (isImage) {
      return (
        <img
          src={`${process.env.REACT_APP_MEDIA_URL}/quiz/${file}`}
          alt="Supplemental"
        />
      );
    }

    if (isVideo) {
      return (
        <ReactPlayer
          url={`${process.env.REACT_APP_MEDIA_URL}/quiz/${file}`}
          playing={false}
          controls={true}
          width="30%"
          height='auto'
          style={{ display: 'block', margin: '0 auto' }}
        />
      );
    }

    return null;
  };

  return (
    <>
      <div
        style={{
          backgroundColor: galaxyKids.backgroundColor,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Header />

        <div
          className="my-4"
          style={{
            minHeight: "100vh",
            width: "100%",
          }}
        >
          <Row className="mt-4">
            <Col lg={12} className="text-center">
              <Progress className="my-4 mx-4" value={80} />
              <div style={{ marginRight: '20px', flexShrink: 0, textAlign: 'right' }}>
                <span
                  id="steps"
                  style={{
                    backgroundColor: galaxyKids.backgroundColor,
                    display: 'block',
                    marginBottom: '10px',
                    fontWeight: 'bold',
                    fontSize: '20px',
                  }}
                >
                  Step 4/5
                </span>
              </div>
              <h2 style={{ color: galaxyKids.textOrange, fontSize: "32px" }}>
                Quiz questions
              </h2>
              <div style={{ fontSize: "20px", margin: '20px',lineHeight: '1.6' }} className="content">
                <p style={{ fontWeight: '20px' }} className="highlight" > {quizDetails ? quizDetails.title : "Loading..."} </p>
                <br />
                <div
                  style={{ maxWidth: '50%', height: 'auto', display: 'block', margin: '0 auto', fontSize: "20px", textAlign: "center" }}
                  className="p-3 w-75 mx-auto"
                >
                  {renderMedia(quizDetails?.file)}
                </div>
                <br />
                <p> {quizDetails ? quizDetails.text : "Loading..."} </p>
                {/* <p>We believe that most students learn a new language to communicate, yet many lack opportunities to practice speaking outside the classroom. With Quippy, students can practice pronunciation while receiving instant feedback and correction, and teachers can easily assign homework and assess oral performance.</p>
                <br />
                <p>Try Quippy for free.</p>
                <br />
                <p>Watch our quick 1-minute video.</p>
                <br />
                <p>If you're interested in our referral program, please <a href="#">sign up here</a>, and we will contact you.</p> */}
              </div>
            </Col>
            {/* <Col>
              
            </Col> */}
          </Row>
          <Row className="mx-auto w-75">
            <Col className="">
              <Button
                style={{
                  width: "20%",
                  borderColor: galaxyKids.textOrange,
                  color: galaxyKids.textOrange,
                }}
                size="large"
                onClick={() => navigate(-1)}
              >
                Previous
              </Button>
            </Col>
            <Col className="text-end">
              <Button
                style={{
                  width: "20%",
                  backgroundColor: galaxyKids.textOrange,
                  border: "none",
                  color: "#fff",
                }}
                size="large"
                onClick={() => navigate("/success")}
              >
                Next
              </Button>
            </Col>
          </Row>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Quiz;
