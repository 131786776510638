import React, { useEffect, useState } from "react";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode'; // Use named import as per the library's documentation
import axios from 'axios';

const GoogleLoginButton = ({ onSuccess, onError }) => {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  const [location, setLocation] = useState({ latitude: 15.8700, longitude: 100.9925 });
  const [error, setError] = useState(null);

  useEffect(() => {
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLocation({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
            setError(null);
          },
          (error) => {
            setError(error.message);
          }
        );
      } else {
        setError('Geolocation is not supported by this browser.');
      }
    };
    
    getLocation();
  }, []);

  // // useEffect(() => {
  // //   if (location.latitude && location.longitude) {
  //       console.log("location > > > >",location);
  // //   }
  // // }, [location]);

  const handleSuccess = async (response) => {
    try {
      // Decode the ID token to get user profile information
      const userObject = jwtDecode(response.credential);
      
      // Prepare the data for the API request
      let data = JSON.stringify({
        first_name: userObject?.given_name,
        last_name: userObject?.family_name,
        email: userObject?.email,
        provider: {
          id: userObject?.sub, // Google's user ID
          name: 'google'
        },
        location: {
          coordinates: [ location.longitude, location.latitude ],
          type: "Point"
        }
      });

      // Configure the API request
      let config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/teacher/social-login`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data: data
      };

      // Make the API request
      const apiResponse = await axios.request(config);
      // Pass the user object to the parent component
      onSuccess(apiResponse);
    } catch (error) {
      console.error('API request failed:', error);
      onError(error);
    }
  };

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <GoogleLogin
        onSuccess={handleSuccess}
        onError={onError}
      />
    </GoogleOAuthProvider>
  );
};

export default GoogleLoginButton;
