import React, { useEffect, useState } from "react";
import { companyLogo } from '../../../assets/images'
import { Link, useNavigate } from 'react-router-dom'

import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
} from "reactstrap";
import { galaxyKids } from "../../../assets/styles/globle";
import { UserOutlined } from "@ant-design/icons";

export default function Header() {

    const navigate = useNavigate();
    const [accessToken, setAccessToken] = useState(false);
    
    useEffect(() => {
        const token = localStorage.getItem("accessToken");
        if (token) {
            setAccessToken(true);
        }
    }, []);

    const handleLogout = () => {
        localStorage.clear();
        localStorage.removeItem("accessToken");
        navigate("/login");
    };

    return (
        <header className='header'>
            <nav className="navbar navbar-expand-lg py-4">
                <div className="container">
                    <Link to="/" className="navbar-brand">
                        <img src={companyLogo} alt='company logo' width="180" />
                    </Link>
                    <button className="navbar-toggler border-0 p-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link to="#" className="nav-link px-4 active" aria-current="page" href="#">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="#" className="nav-link px-4" href="#">School Version</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="#" className="nav-link px-4" href="#">English</Link>
                            </li>
                            {accessToken ? (
                                <>
                                    <li className='nav-button'>
                                        <button className='btn btn-outline-primary ms-2' onClick={() => navigate("/")} >Home</button>
                                    </li>
                                    <UncontrolledDropdown nav inNavbar className="d-md-block d-none">
                                        <DropdownToggle
                                            nav
                                            caret
                                            style={{ color: galaxyKids.textColor }}
                                        >
                                            <UserOutlined
                                                style={{ fontSize: "25px", color: galaxyKids.textColor }}
                                            />
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                            <DropdownItem>Account Settings</DropdownItem>
                                            <DropdownItem onClick={() => navigate("/profile")}>
                                                Your Profile
                                            </DropdownItem>

                                            <DropdownItem divider />
                                            <DropdownItem onClick={() => handleLogout()}>
                                                Sign Out
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </>
                            ):(
                                <>
                                <button className='btn btn-outline-primary ms-2' onClick={() => navigate("/login")} >Login</button>
                                <button className='btn btn-primary ms-2' onClick={() => navigate("/register")} >Sign up</button>
                                </>
                            )}
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    )
}
