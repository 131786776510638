import React, { useEffect, useState } from "react";
import {
    Input,
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Collapse,
    Container,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
} from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { galaxyKids } from "../assets/styles/globle";
import { Button } from "antd";
import { useSettings } from '../context/CountryContext';

const Header = (props) => {

    const { configuredSettings, loading } = useSettings();
    const navigate = useNavigate();
    const location = useLocation();
    const [isOpen, setIsOpen] = React.useState(false);
    const toggleNavbar = () => setIsOpen(!isOpen);
    const [accessToken, setAccessToken] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem("accessToken");
        if (token) {
            setAccessToken(true);
        }
    }, []);

    const handleLogout = () => {
        localStorage.clear();
        localStorage.removeItem("accessToken");
        navigate("/login");
    };

    const isActiveLink = (link) => {
        return location.pathname === link;
    };

    const handleRedirect = () => {
        window.location.href = 'https://quippy.co/';
    };

    return (
        <>
            {props.loaderProp && (
                <div className="page-loader-wrapper" v-if="isLoading">
                    <div className="loader">
                        <div>
                            <img src={require("../assets/images/gLogo.png")} style={{ width: "150px", height: "60px" }} alt="Quippy" />
                            <p>Please wait...</p>
                        </div>
                    </div>
                </div>
            )}
            <Navbar
                className="fixed-top"
                style={{
                    backgroundColor: galaxyKids.whiteBackground,
                }}
                light
                expand="md"
            >
                <NavbarBrand href="#" className="d-inline-block">
                    <img
                        src={`${process.env.REACT_APP_MEDIA_URL}/logo/${configuredSettings?.data?.logo}`}
                        style={{ width: "140px", height: "50px" }}
                        alt="Logo"
                        onClick={handleRedirect}
                    />
                </NavbarBrand>
                <button className="navbar-toggler ms-auto" type="button" onClick={toggleNavbar}>
                    <span className="navbar-toggler-icon" />
                </button>
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="ms-auto auth-buttons" navbar>
                        <NavItem>
                            <Button
                                className="btn btn-primary d-md-flex d-none"
                                onClick={() => navigate("/")}
                                style={{ display: 'flex' }}
                            >
                                HOME
                            </Button>
                        </NavItem>
                        {accessToken ? (
                            <>
                                <UncontrolledDropdown nav inNavbar className="d-md-block d-none">
                                    <DropdownToggle
                                        nav
                                        caret
                                        style={{ color: galaxyKids.textColor }}
                                    >
                                        <UserOutlined
                                            style={{ fontSize: "25px", color: galaxyKids.textColor }}
                                        />
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem>Account Settings</DropdownItem>
                                        <DropdownItem onClick={() => navigate("/profile")}>
                                            Your Profile
                                        </DropdownItem>

                                        <DropdownItem divider />
                                        <DropdownItem onClick={() => handleLogout()}>
                                            Sign Out
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <ul className="list-unstyled w-100 d-md-none">
                                    <hr />
                                    <li onClick={() => navigate("/")}>Home</li>
                                    <hr />
                                    <li>Account Settings</li>
                                    <hr />
                                    <li onClick={() => navigate("/profile")}>Your Profile</li>
                                    <hr />
                                    <li onClick={() => handleLogout()}>Sign Out</li>
                                    <hr />
                                </ul>
                            </>
                        ) : (
                            <div className="auth-buttons">
                                <Button
                                    className="btn btn-primary signup-button"
                                    onClick={() => navigate("/login")}
                                    style={{ padding: '0 20px', lineHeight: '32px', height: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                >
                                    Login
                                </Button>
                                <Button
                                    className="btn btn-info signup-button"
                                    onClick={() => navigate("/register")}
                                    style={{ padding: '0 20px', lineHeight: '32px', height: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                >
                                    Sign Up
                                </Button>
                            </div>
                        )}
                    </Nav>
                </Collapse>
            </Navbar>
        </>
    );
};

export default Header;
