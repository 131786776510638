import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import { Button, Form, Input, Upload, message } from "antd";
import { galaxyKids } from "../../assets/styles/globle";

const AddIntro = (props) => {
  const { addIntroModalOpen, setAddIntroModalOpen, videoFile, setVideoFile } = props;
  const [loading, setLoading] = useState(false);
  const [youtubeLink, setYoutubeLink] = useState("");

  const [form] = Form.useForm();

  const showModal = () => {
    setAddIntroModalOpen(true);
  };

  const handleCancel = () => {
    setAddIntroModalOpen(false);
  };

  const onFinish = async (values) => {
    setLoading(true);
    const introVideo = values?.introVideo?.file;
    const formData = new FormData();
    if (introVideo) {
      formData.append("video", introVideo);
    }
    formData.append("youtube_video_link", youtubeLink);
    const token = localStorage.getItem("accessToken");
    const teacherId = localStorage.getItem("teacherId");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/teacher/intro-video/${teacherId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        message.success("Introduction video uploaded successfully");
        setAddIntroModalOpen(false);
      } else {
        message.error(response.data.message || "Something went wrong");
      }
    } catch (error) {
      console.error("Error:", error);
      message.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleRemove = async () => {
    const token = localStorage.getItem("accessToken");
    const teacherId = localStorage.getItem("teacherId");
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/teacher/intro-video/remove-video/${teacherId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      message.success("Video removed successfully.");
      setVideoFile(null);
    } catch (error) {
      message.error("Failed to remove video.");
      console.error("Failed to remove video", error);
    }
  };

  const handleUpload = async ({ file }) => {
    if(!file.status) {
      const videoUrl = URL.createObjectURL(file);
      const videoElement = document.createElement("video");

      videoElement.src = videoUrl;
      videoElement.onloadedmetadata = () => {
        URL.revokeObjectURL(videoUrl);
        const duration = videoElement.duration;
        if (duration > 60) { // Duration limit of 1 minute (60 seconds)
          message.error("Video duration exceeds the 1-minute limit!");
          setVideoFile(null);
          return;
        } else {
          const fileNameShortened = file.name.substring(0, 10); // Get the first 10 characters of the file name
          const formData = new FormData();
          formData.append("video", file);
          const token = localStorage.getItem("accessToken");
          const teacherId = localStorage.getItem("teacherId");

          try {
            const response = axios.post(
              `${process.env.REACT_APP_API_URL}/teacher/intro-video/${teacherId}`,
              formData,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            message.success(`${fileNameShortened} uploaded successfully.`);
            setVideoFile(file);
          } catch (error) {
            message.error(`${fileNameShortened} upload failed.`);
            console.error('Upload error:', error);
          }
        }
      };
    }
  };

  return (
    <>
      <Modal
        isOpen={addIntroModalOpen}
        toggle={handleCancel}
        contentClassName="custom-modal-content"
        backdropClassName="custom-modal-backdrop"
      >
        <ModalHeader
          toggle={handleCancel}
          style={{
            fontSize: "18px",
            fontWeight: "bold",
            color: galaxyKids.textOrange,
            backgroundColor: galaxyKids.backgroundColor,
          }}
        >
          Add Introduction
        </ModalHeader>
        <ModalBody
          className="custom-modal-body"
          style={{
            backgroundColor: galaxyKids.backgroundColor,
          }}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            style={{
              backgroundColor: galaxyKids.backgroundColor,
            }}
          >
            <Form.Item
              name="introVideo"
              label="Introduction Video"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please upload your introduction video!",
              //   },
              // ]}
            >
              <Upload
                action=""
                beforeUpload={() => false} // Disable automatic upload
                onChange={handleUpload}
                onRemove={handleRemove}
                fileList={videoFile ? [videoFile] : []}
                maxCount={1}
                accept="video/*"
              >
                <Button icon={<UploadOutlined />}>Upload Video</Button>
              </Upload>
            </Form.Item>
            <Form.Item
              name="youtubeLink"
              label="YouTube Link"
              rules={[
                // {
                //   required: true,
                //   message: "Please provide your YouTube video link!",
                // },
                {
                  type: 'url',
                  message: 'Please enter a valid URL!',
                },
              ]}
            >
              <Input
                value={youtubeLink}
                onChange={(e) => setYoutubeLink(e.target.value)}
                placeholder="Enter YouTube link"
              />
            </Form.Item>
          </Form>
        </ModalBody>
        <ModalFooter
          className="custom-modal-footer"
          style={{
            backgroundColor: galaxyKids.backgroundColor,
          }}
        >
          <Button
            className="border-0"
            style={{
              color: galaxyKids.textOrange,
              backgroundColor: galaxyKids.backgroundColor,
            }}
            onClick={handleCancel}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            className="border-0"
            style={{ backgroundColor: galaxyKids.textOrange, color: "#fff" }}
            onClick={() => form.submit()}
            loading={loading}
          >
            Upload
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddIntro;
