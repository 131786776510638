import React, { useState, useEffect, useCallback } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { Link } from 'react-router-dom';
import axios from "axios";
import { companyLogo } from '../assets/images';
import { useSettings } from '../context/CountryContext';
import debounce from "lodash/debounce";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { FaStar } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const TutorsScreen = () => {
    const navigate = useNavigate();
  const { configuredSettings } = useSettings();
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [selectedPrice, setSelectedPrice] = useState('');
  const [currentPage, setCurrentPage] = useState(
    JSON.parse(localStorage.getItem("tutorFilters"))?.page || 1
  );
  const [limit, setLimit] = useState(
    JSON.parse(localStorage.getItem("tutorFilters"))?.limit || 15
  );
  const [loader, setLoading] = useState(true);
  const [tutors, setTutors] = useState([]);
  const [metaData, setMetaData] = useState({});
  const [countries, setCountries] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  const [showPopup, setShowPopup] = useState(false);
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(null);
  const [review, setReview] = useState('');
  const [currentUserId, setCurrentUserId] = useState(null);

  const fetchCountries = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/countries`
      );
      setCountries(response.data.data);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  }, []);

  const fetchTutors = useCallback(
    async (page, limit) => {
      setLoading(true);
      const params = { page, paginate: limit};
        if(selectedLanguage){
            setCurrentPage(1);
            params.language = selectedLanguage;
        }
        if(selectedPrice){
            setCurrentPage(1);
            params.pricing = selectedPrice;
        }
        
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/teacher`,
          { params }
        );
        if (page === 1) {
          setTutors(response?.data?.data?.teachers);
        } else {
          setTutors((prevTutors) => [
            ...prevTutors,
            ...response?.data?.data?.teachers,
          ]);
        }
        setMetaData(response?.data?.data?.metadata);
        setTotalPages(response?.data?.data?.metadata?.totalPages);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    },
    [currentPage, limit, selectedLanguage, selectedPrice] // Added selectedLanguage to the dependency array
  );
  

  useEffect(() => {
    fetchTutors(currentPage, limit);
    fetchCountries();
  }, [fetchTutors, fetchCountries]);

  const handleScroll = debounce(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight &&
      currentPage < totalPages
    ) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  }, 300);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const [mobileFilter, setMobileFilter] = useState(false);
  const mobileFilterToggle = () => {
    setMobileFilter(!mobileFilter);
  };

  useEffect(() => {
    if (mobileFilter) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [mobileFilter]);

  const handleLanguageChange = (event) => { setSelectedLanguage(event.target.value); };
  const handlePriceChange = (event) => { setSelectedPrice(event.target.value); };
  
  const handlePopupOpen = (userId) => {
    setCurrentUserId(userId);
    setShowPopup(true);
  };

  // Toggle to close the popup
  const handlePopupClose = () => {
    setShowPopup(false);
    setCurrentUserId(null); // Reset user ID
    setRating(0); // Reset rating
    setReview(''); // Reset review text
  };


  const togglePopup = () => setShowPopup(!showPopup);

  const handleSubmit = async () => {
    const loggedinUserId = localStorage.getItem("teacherId");
    console.log('User ID:', currentUserId);
    console.log('loggedinUserId ID:', loggedinUserId);
    console.log('Rating:', rating);
    console.log('Review:', review);
    try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/review`,
          {
            "reviewer_id": loggedinUserId,
            "reviewed_teacher_id": currentUserId,
            "rating": rating,
            "comment": review
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        console.log("response > > > > >",response);
    } catch (error) {
    //message.error(error?.response?.data?.message);
        console.log(error?.response?.data?.message);
    }
    handlePopupClose(); // Close the popup after submission
  };

    return (
        <>
        <Modal isOpen={showPopup} toggle={togglePopup}>
            <ModalHeader toggle={togglePopup}>Rate Your Experience</ModalHeader>
            <ModalBody>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '5px' }}>
                {[...Array(5)].map((_, i) => (
                <FaStar
                    key={i}
                    size={24}
                    color={i < (hover || rating) ? '#ffc107' : '#e4e5e9'}
                    onClick={() => setRating(i + 1)}
                    onMouseEnter={() => setHover(i + 1)}
                    onMouseLeave={() => setHover(null)}
                />
                ))}
            </div>
            <textarea
                className="form-control mt-3"
                rows="3"
                placeholder="Write your review..."
                value={review}
                onChange={(e) => setReview(e.target.value)}
            />
            </ModalBody>
            <ModalFooter>
                {
                   localStorage.getItem("accessToken") ? <>
                        <Button color="secondary" onClick={togglePopup}>Cancel</Button>
                        <Button color="primary" onClick={handleSubmit}>Submit</Button>
                    </> : <span className="text-muted">Note: To review or rate Tutors Please <Link to="/login" className='d-inline-block me-1'>Login</Link> </span>
                }
            
            </ModalFooter>
        </Modal>
            <div className="container tutor-banner-slider pb-4 mb-3">
                <Swiper
                    modules={[Pagination, Autoplay]}
                    spaceBetween={20}
                    slidesPerView={1}
                    pagination={{
                        clickable: true,
                    }}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    speed={1000}
                >
                    {configuredSettings?.data?.banner_media.map((item, key) => (

                        <SwiperSlide key={key}>
                            <div className='slider-box position-relative overflow-hidden '>
                                {(item.endsWith('.mp4') || item.includes('youtube.com') || item.includes('vimeo.com')) ?
                                    <video
                                        autoPlay
                                        muted
                                        className='w-100 h-100 object-fit-cover'
                                    >

                                        <source src={`${process.env.REACT_APP_BASE_URL}/api/banner/${item}`} type="video/mp4" />
                                    </video>
                                    : <img src={`${process.env.REACT_APP_BASE_URL}/api/banner/${item}`} alt="product banner" className='object-fit-cover' width="1280" height="488" />}
                                <div className='p-3 w-100 position-absolute top-50 start-50 translate-middle z-1 text-center'>
                                    <h1 className='title fw-semibold text-white'>Find The Best Tutors</h1>
                                    <h2 className='subtitle text-white fw-medium mb-0'>👋 Hello and welcome! Here we occasionally post about making impacts.</h2>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <div className='container'>
                <h3 className='tutor-title fw-semibold mb-4 pb-2 d-flex align-items-center gap-3'>
                    <button onClick={mobileFilterToggle} className={`mobile-setting d-md-none d-flex align-items-center justify-content-center p-sm-2 p-1 border-0 rounded-circle ${mobileFilter ? 'active' : ''}`}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.3333 5.41669H13.3333" stroke="#292D32" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M5.00002 5.41669H1.66669" stroke="#292D32" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M8.33335 8.33333C9.94418 8.33333 11.25 7.0275 11.25 5.41667C11.25 3.80584 9.94418 2.5 8.33335 2.5C6.72252 2.5 5.41669 3.80584 5.41669 5.41667C5.41669 7.0275 6.72252 8.33333 8.33335 8.33333Z" stroke="#292D32" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M18.3333 14.5833H15" stroke="#292D32" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M6.66669 14.5833H1.66669" stroke="#292D32" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M11.6667 17.5C13.2775 17.5 14.5833 16.1942 14.5833 14.5834C14.5833 12.9725 13.2775 11.6667 11.6667 11.6667C10.0558 11.6667 8.75 12.9725 8.75 14.5834C8.75 16.1942 10.0558 17.5 11.6667 17.5Z" stroke="#292D32" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
                    Find Expert Chinese Tutors
                </h3>
                <div className='tutor-wrapper d-flex'>
                    <div className={`tutor-card-filter d-md-block ${mobileFilter ? 'active' : ''}`}>
                        <div className='title d-flex align-items-center fw-medium mb-2 pb-1'>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.3333 5.41669H13.3333" stroke="#292D32" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M5.00002 5.41669H1.66669" stroke="#292D32" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M8.33335 8.33333C9.94418 8.33333 11.25 7.0275 11.25 5.41667C11.25 3.80584 9.94418 2.5 8.33335 2.5C6.72252 2.5 5.41669 3.80584 5.41669 5.41667C5.41669 7.0275 6.72252 8.33333 8.33335 8.33333Z" stroke="#292D32" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M18.3333 14.5833H15" stroke="#292D32" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M6.66669 14.5833H1.66669" stroke="#292D32" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M11.6667 17.5C13.2775 17.5 14.5833 16.1942 14.5833 14.5834C14.5833 12.9725 13.2775 11.6667 11.6667 11.6667C10.0558 11.6667 8.75 12.9725 8.75 14.5834C8.75 16.1942 10.0558 17.5 11.6667 17.5Z" stroke="#292D32" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <span className='d-inline-block ms-2 ps-1'>Filter by</span>
                        </div>
                        {/* <ul className='selected-filter d-flex flex-wrap p-0 m-0 list-unstyled'>
                            <li className='flex align-items-center rounded-pill'>
                                <span className='d-inline-block me-1'>English</span>
                                <svg width="14" height="14" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.00008 1.66669C3.78591 1.66669 1.16675 4.28585 1.16675 7.50002C1.16675 10.7142 3.78591 13.3334 7.00008 13.3334C10.2142 13.3334 12.8334 10.7142 12.8334 7.50002C12.8334 4.28585 10.2142 1.66669 7.00008 1.66669ZM8.96008 8.84169C9.12925 9.01085 9.12925 9.29085 8.96008 9.46002C8.87258 9.54752 8.76175 9.58835 8.65092 9.58835C8.54008 9.58835 8.42925 9.54752 8.34175 9.46002L7.00008 8.11835L5.65841 9.46002C5.57091 9.54752 5.46008 9.58835 5.34925 9.58835C5.23842 9.58835 5.12758 9.54752 5.04008 9.46002C4.87092 9.29085 4.87092 9.01085 5.04008 8.84169L6.38175 7.50002L5.04008 6.15835C4.87092 5.98919 4.87092 5.70919 5.04008 5.54002C5.20925 5.37085 5.48925 5.37085 5.65841 5.54002L7.00008 6.88169L8.34175 5.54002C8.51092 5.37085 8.79092 5.37085 8.96008 5.54002C9.12925 5.70919 9.12925 5.98919 8.96008 6.15835L7.61842 7.50002L8.96008 8.84169Z" fill="#6B6B6B" />
                                </svg>
                            </li>
                            <li className='flex align-items-center rounded-pill'>
                                <span className='d-inline-block me-1'>English</span>
                                <svg width="14" height="14" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.00008 1.66669C3.78591 1.66669 1.16675 4.28585 1.16675 7.50002C1.16675 10.7142 3.78591 13.3334 7.00008 13.3334C10.2142 13.3334 12.8334 10.7142 12.8334 7.50002C12.8334 4.28585 10.2142 1.66669 7.00008 1.66669ZM8.96008 8.84169C9.12925 9.01085 9.12925 9.29085 8.96008 9.46002C8.87258 9.54752 8.76175 9.58835 8.65092 9.58835C8.54008 9.58835 8.42925 9.54752 8.34175 9.46002L7.00008 8.11835L5.65841 9.46002C5.57091 9.54752 5.46008 9.58835 5.34925 9.58835C5.23842 9.58835 5.12758 9.54752 5.04008 9.46002C4.87092 9.29085 4.87092 9.01085 5.04008 8.84169L6.38175 7.50002L5.04008 6.15835C4.87092 5.98919 4.87092 5.70919 5.04008 5.54002C5.20925 5.37085 5.48925 5.37085 5.65841 5.54002L7.00008 6.88169L8.34175 5.54002C8.51092 5.37085 8.79092 5.37085 8.96008 5.54002C9.12925 5.70919 9.12925 5.98919 8.96008 6.15835L7.61842 7.50002L8.96008 8.84169Z" fill="#6B6B6B" />
                                </svg>
                            </li>
                        </ul> */}
                        <div className='tutor-search position-relative'>
                            <input type='text' placeholder='Search by keyword' className='p-3 w-100 rounded-3 border-0 bg-surface' />
                            <svg className='search-icon position-absolute top-50 translate-middle-y' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.7">
                                    <path d="M7.66665 14.5C3.89998 14.5 0.833313 11.4333 0.833313 7.66665C0.833313 3.89998 3.89998 0.833313 7.66665 0.833313C11.4333 0.833313 14.5 3.89998 14.5 7.66665C14.5 11.4333 11.4333 14.5 7.66665 14.5ZM7.66665 1.83331C4.44665 1.83331 1.83331 4.45331 1.83331 7.66665C1.83331 10.88 4.44665 13.5 7.66665 13.5C10.8866 13.5 13.5 10.88 13.5 7.66665C13.5 4.45331 10.8866 1.83331 7.66665 1.83331Z" fill="#101820" />
                                    <path d="M14.6666 15.1666C14.54 15.1666 14.4133 15.12 14.3133 15.02L12.98 13.6866C12.7866 13.4933 12.7866 13.1733 12.98 12.98C13.1733 12.7866 13.4933 12.7866 13.6866 12.98L15.02 14.3133C15.2133 14.5066 15.2133 14.8266 15.02 15.02C14.92 15.12 14.7933 15.1666 14.6666 15.1666Z" fill="#101820" />
                                </g>
                            </svg>
                        </div>
                        <div className="accordion tutor-filter" id="accordionExample">
                            {/* <div className="accordion-item">
                                <button className="accordion-button fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    All Languages
                                </button>
                                <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <ul className='m-0 p-0 list-unstyled'>
                                            {configuredSettings?.data?.languages && configuredSettings?.data?.languages.map((language, index) => (
                                                <li key={index} className='d-flex align-items-center justify-content-between'>
                                                    <div className="form-check m-0">
                                                        <input className="form-check-input" type="checkbox" value="" id={`language-${index}`} />
                                                        <label className="form-check-label" htmlFor={`language-${index}`}>
                                                            {language}
                                                        </label>
                                                    </div>
                                                    <span className='badge d-inline-block ms-2 rounded-pill text-primary fw-medium py-1 px-2'>02</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <button className="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseCountries" aria-expanded="false" aria-controls="collapseCountries">
                                    All Countries
                                </button>
                                <div id="collapseCountries" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <ul className='m-0 p-0 list-unstyled'>
                                            {countries.map((country, index) => (
                                                <li key={index} className='d-flex align-items-center justify-content-between'>
                                                    <div className="form-check m-0">
                                                        <input className="form-check-input" type="checkbox" value={country.code} id={`country-${index}`} />
                                                        <label className="form-check-label" htmlFor={`country-${index}`}>
                                                            {country.name}
                                                        </label>
                                                    </div>
                                                    <span className='badge d-inline-block ms-2 rounded-pill text-primary fw-medium py-1 px-2'>02</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <button className="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    All States
                                </button>
                                <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <button className="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    All Cities
                                </button>
                                <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <button className="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    Radius
                                </button>
                                <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <ul className='m-0 p-0 list-unstyled'>
                                            {configuredSettings?.data?.radius && configuredSettings?.data?.radius.map((radius, index) => (
                                                <li key={index} className='d-flex align-items-center justify-content-between'>
                                                    <div className="form-check m-0">
                                                        <input className="form-check-input" type="checkbox" value="" id={`radius-${index}`} />
                                                        <label className="form-check-label" htmlFor={`radius-${index}`}>
                                                            {radius}
                                                        </label>
                                                    </div>
                                                    <span className='badge d-inline-block ms-2 rounded-pill text-primary fw-medium py-1 px-2'>02</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <button className="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                    Availability
                                </button>
                                <div id="collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <ul className='m-0 p-0 list-unstyled'>
                                            {configuredSettings?.data?.availability && configuredSettings?.data?.availability.map((availability, index) => (
                                                <li key={index} className='d-flex align-items-center justify-content-between'>
                                                    <div className="form-check m-0">
                                                        <input className="form-check-input" type="checkbox" value="" id={`availability-${index}`} />
                                                        <label className="form-check-label" htmlFor={`availability-${index}`}>
                                                            {availability}
                                                        </label>
                                                    </div>
                                                    <span className='badge d-inline-block ms-2 rounded-pill text-primary fw-medium py-1 px-2'>02</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <button className="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                    Price Per Lesson
                                </button>
                                <div id="collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <ul className='m-0 p-0 list-unstyled'>
                                            {configuredSettings?.data?.price && configuredSettings?.data?.price.map((price, index) => (
                                                <li key={index} className='d-flex align-items-center justify-content-between'>
                                                    <div className="form-check m-0">
                                                        <input className="form-check-input" type="checkbox" value="" id={`price-${index}`} />
                                                        <label className="form-check-label" htmlFor={`price-${index}`}>
                                                            USD {price}
                                                        </label>
                                                    </div>
                                                    <span className='badge d-inline-block ms-2 rounded-pill text-primary fw-medium py-1 px-2'>02</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <button className="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                    Expertise
                                </button>
                                <div id="collapseEight" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <ul className='m-0 p-0 list-unstyled'>
                                            {configuredSettings?.data?.experience && configuredSettings?.data?.experience.map((experience, index) => (
                                                <li key={index} className='d-flex align-items-center justify-content-between'>
                                                    <div className="form-check m-0">
                                                        <input className="form-check-input" type="checkbox" value="" id={`experience-${index}`} />
                                                        <label className="form-check-label" htmlFor={`experience-${index}`}>
                                                            {experience} Years
                                                        </label>
                                                    </div>
                                                    <span className='badge d-inline-block ms-2 rounded-pill text-primary fw-medium py-1 px-2'>02</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div> */}
                            <div className="accordion-item">
                                <button className="accordion-button fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    All Languages
                                </button>
                                <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                    <ul className='m-0 p-0 list-unstyled'>
                                        {configuredSettings?.data?.languages && configuredSettings?.data?.languages.map((language, index) => (
                                        <li key={index} className='d-flex align-items-center justify-content-between'>
                                            <div className="form-check m-0">
                                            <input 
                                                className="form-check-input" 
                                                type="radio" 
                                                name="language" 
                                                id={`language-${index}`} 
                                                value={language} 
                                                onChange={handleLanguageChange} 
                                            />
                                            <label className="form-check-label" htmlFor={`language-${index}`}>
                                                {language}
                                            </label>
                                            </div>
                                            {/* <span className='badge d-inline-block ms-2 rounded-pill text-primary fw-medium py-1 px-2'>02</span> */}
                                        </li>
                                        ))}
                                    </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <button className="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                    Price Per Lesson
                                </button>
                                <div id="collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <ul className='m-0 p-0 list-unstyled'>
                                            {configuredSettings?.data?.price && configuredSettings?.data?.price.map((price, index) => (
                                                <li key={index} className='d-flex align-items-center justify-content-between'>
                                                    <div className="form-check m-0">
                                                        <input 
                                                            className="form-check-input" 
                                                            type="radio" 
                                                            name="price" 
                                                            id={`price-${index}`}
                                                            value={price} 
                                                            onChange={handlePriceChange} 
                                                        />
                                                        <label className="form-check-label" htmlFor={`price-${index}`}>
                                                            USD {price}
                                                        </label>
                                                    </div>
                                                    {/* <span className='badge d-inline-block ms-2 rounded-pill text-primary fw-medium py-1 px-2'>02</span> */}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='tutor-card-wrapper'>
                        {tutors.map((item, key) => (
                            <Link key={key} to={`/tutor/${item._id}`} className='tutor-card d-inline-flex flex-column rounded-4 border'>
                                
                                <div className='img-box position-relative rounded-4 overflow-hidden text-center'>
                                    { item?.picture ? 
                                        <img src={`${process.env.REACT_APP_MEDIA_URL}/user/image/` + item.picture} alt='tutor' width={305} height={190} className='w-100 h-100 object-fit-cover' /> : 
                                        <img src={companyLogo} alt='tutor' width={305} height={190} className='object-fit-contain' />
                                    }
                                </div>
                                <div className='content-box p-3'>
                                
                                    <h4 className='title mb-1 fw-semibold d-flex justify-content-between'>
                                        {`${item.first_name} ${item.last_name}`}
                                        <span>
                                            <span className='fw-semibold'>{item.pricing}</span>
                                            <span className='text-secondary fw-normal'>/hr</span>
                                        </span>
                                    </h4>
                                    <div className='rating-review mb-3 d-flex text-secondary'>
                                        <Link to="#" onClick={() => handlePopupOpen(item._id)} >
                                            <div className='d-flex me-1'>
                                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.84841 9.33333C3.91258 9.0475 3.79591 8.63916 3.59175 8.435L2.17425 7.0175C1.73091 6.57416 1.55591 6.10166 1.68425 5.69333C1.81841 5.285 2.23258 5.005 2.85091 4.9L4.67091 4.59666C4.93341 4.55 5.25425 4.31666 5.37675 4.0775L6.38008 2.065C6.67175 1.4875 7.06841 1.16666 7.50008 1.16666C7.93175 1.16666 8.32841 1.4875 8.62008 2.065L9.62341 4.0775C9.69925 4.22916 9.85675 4.375 10.0259 4.47416L3.74341 10.7567C3.66175 10.8383 3.52175 10.7625 3.54508 10.6458L3.84841 9.33333Z" fill="#AE8723" />
                                                    <path d="M11.4084 8.435C11.1984 8.645 11.0818 9.0475 11.1518 9.33333L11.5543 11.0892C11.7234 11.8183 11.6184 12.3667 11.2568 12.6292C11.1109 12.7342 10.9359 12.7867 10.7318 12.7867C10.4343 12.7867 10.0843 12.6758 9.69926 12.4483L7.99009 11.4333C7.72176 11.2758 7.27842 11.2758 7.01009 11.4333L5.30092 12.4483C4.65342 12.8275 4.09926 12.8917 3.74342 12.6292C3.60926 12.53 3.51009 12.3958 3.44592 12.2208L10.5393 5.1275C10.8076 4.85916 11.1868 4.73666 11.5543 4.80083L12.1434 4.9C12.7618 5.005 13.1759 5.285 13.3101 5.69333C13.4384 6.10166 13.2634 6.57416 12.8201 7.0175L11.4084 8.435Z" fill="#AE8723" />
                                                </svg>
                                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.84841 9.33333C3.91258 9.0475 3.79591 8.63916 3.59175 8.435L2.17425 7.0175C1.73091 6.57416 1.55591 6.10166 1.68425 5.69333C1.81841 5.285 2.23258 5.005 2.85091 4.9L4.67091 4.59666C4.93341 4.55 5.25425 4.31666 5.37675 4.0775L6.38008 2.065C6.67175 1.4875 7.06841 1.16666 7.50008 1.16666C7.93175 1.16666 8.32841 1.4875 8.62008 2.065L9.62341 4.0775C9.69925 4.22916 9.85675 4.375 10.0259 4.47416L3.74341 10.7567C3.66175 10.8383 3.52175 10.7625 3.54508 10.6458L3.84841 9.33333Z" fill="#AE8723" />
                                                    <path d="M11.4084 8.435C11.1984 8.645 11.0818 9.0475 11.1518 9.33333L11.5543 11.0892C11.7234 11.8183 11.6184 12.3667 11.2568 12.6292C11.1109 12.7342 10.9359 12.7867 10.7318 12.7867C10.4343 12.7867 10.0843 12.6758 9.69926 12.4483L7.99009 11.4333C7.72176 11.2758 7.27842 11.2758 7.01009 11.4333L5.30092 12.4483C4.65342 12.8275 4.09926 12.8917 3.74342 12.6292C3.60926 12.53 3.51009 12.3958 3.44592 12.2208L10.5393 5.1275C10.8076 4.85916 11.1868 4.73666 11.5543 4.80083L12.1434 4.9C12.7618 5.005 13.1759 5.285 13.3101 5.69333C13.4384 6.10166 13.2634 6.57416 12.8201 7.0175L11.4084 8.435Z" fill="#AE8723" />
                                                </svg>
                                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.84841 9.33333C3.91258 9.0475 3.79591 8.63916 3.59175 8.435L2.17425 7.0175C1.73091 6.57416 1.55591 6.10166 1.68425 5.69333C1.81841 5.285 2.23258 5.005 2.85091 4.9L4.67091 4.59666C4.93341 4.55 5.25425 4.31666 5.37675 4.0775L6.38008 2.065C6.67175 1.4875 7.06841 1.16666 7.50008 1.16666C7.93175 1.16666 8.32841 1.4875 8.62008 2.065L9.62341 4.0775C9.69925 4.22916 9.85675 4.375 10.0259 4.47416L3.74341 10.7567C3.66175 10.8383 3.52175 10.7625 3.54508 10.6458L3.84841 9.33333Z" fill="#AE8723" />
                                                    <path d="M11.4084 8.435C11.1984 8.645 11.0818 9.0475 11.1518 9.33333L11.5543 11.0892C11.7234 11.8183 11.6184 12.3667 11.2568 12.6292C11.1109 12.7342 10.9359 12.7867 10.7318 12.7867C10.4343 12.7867 10.0843 12.6758 9.69926 12.4483L7.99009 11.4333C7.72176 11.2758 7.27842 11.2758 7.01009 11.4333L5.30092 12.4483C4.65342 12.8275 4.09926 12.8917 3.74342 12.6292C3.60926 12.53 3.51009 12.3958 3.44592 12.2208L10.5393 5.1275C10.8076 4.85916 11.1868 4.73666 11.5543 4.80083L12.1434 4.9C12.7618 5.005 13.1759 5.285 13.3101 5.69333C13.4384 6.10166 13.2634 6.57416 12.8201 7.0175L11.4084 8.435Z" fill="#AE8723" />
                                                </svg>
                                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.84841 9.33333C3.91258 9.0475 3.79591 8.63916 3.59175 8.435L2.17425 7.0175C1.73091 6.57416 1.55591 6.10166 1.68425 5.69333C1.81841 5.285 2.23258 5.005 2.85091 4.9L4.67091 4.59666C4.93341 4.55 5.25425 4.31666 5.37675 4.0775L6.38008 2.065C6.67175 1.4875 7.06841 1.16666 7.50008 1.16666C7.93175 1.16666 8.32841 1.4875 8.62008 2.065L9.62341 4.0775C9.69925 4.22916 9.85675 4.375 10.0259 4.47416L3.74341 10.7567C3.66175 10.8383 3.52175 10.7625 3.54508 10.6458L3.84841 9.33333Z" fill="#AE8723" />
                                                    <path d="M11.4084 8.435C11.1984 8.645 11.0818 9.0475 11.1518 9.33333L11.5543 11.0892C11.7234 11.8183 11.6184 12.3667 11.2568 12.6292C11.1109 12.7342 10.9359 12.7867 10.7318 12.7867C10.4343 12.7867 10.0843 12.6758 9.69926 12.4483L7.99009 11.4333C7.72176 11.2758 7.27842 11.2758 7.01009 11.4333L5.30092 12.4483C4.65342 12.8275 4.09926 12.8917 3.74342 12.6292C3.60926 12.53 3.51009 12.3958 3.44592 12.2208L10.5393 5.1275C10.8076 4.85916 11.1868 4.73666 11.5543 4.80083L12.1434 4.9C12.7618 5.005 13.1759 5.285 13.3101 5.69333C13.4384 6.10166 13.2634 6.57416 12.8201 7.0175L11.4084 8.435Z" fill="#AE8723" />
                                                </svg>
                                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.84841 9.33333C3.91258 9.0475 3.79591 8.63916 3.59175 8.435L2.17425 7.0175C1.73091 6.57416 1.55591 6.10166 1.68425 5.69333C1.81841 5.285 2.23258 5.005 2.85091 4.9L4.67091 4.59666C4.93341 4.55 5.25425 4.31666 5.37675 4.0775L6.38008 2.065C6.67175 1.4875 7.06841 1.16666 7.50008 1.16666C7.93175 1.16666 8.32841 1.4875 8.62008 2.065L9.62341 4.0775C9.69925 4.22916 9.85675 4.375 10.0259 4.47416L3.74341 10.7567C3.66175 10.8383 3.52175 10.7625 3.54508 10.6458L3.84841 9.33333Z" fill="#AE8723" />
                                                    <path d="M11.4084 8.435C11.1984 8.645 11.0818 9.0475 11.1518 9.33333L11.5543 11.0892C11.7234 11.8183 11.6184 12.3667 11.2568 12.6292C11.1109 12.7342 10.9359 12.7867 10.7318 12.7867C10.4343 12.7867 10.0843 12.6758 9.69926 12.4483L7.99009 11.4333C7.72176 11.2758 7.27842 11.2758 7.01009 11.4333L5.30092 12.4483C4.65342 12.8275 4.09926 12.8917 3.74342 12.6292C3.60926 12.53 3.51009 12.3958 3.44592 12.2208L10.5393 5.1275C10.8076 4.85916 11.1868 4.73666 11.5543 4.80083L12.1434 4.9C12.7618 5.005 13.1759 5.285 13.3101 5.69333C13.4384 6.10166 13.2634 6.57416 12.8201 7.0175L11.4084 8.435Z" fill="#AE8723" />
                                                </svg>
                                            </div>
                                        </Link>
                                        <span className='d-inline-block pe-2 me-2 border-end fw-semibold'>{item.rating}</span>
                                        <Link to={`/tutor/${item._id}/review`} >
                                            <span className='text-decoration-underline'>{item.review} Review</span>
                                        </Link>
                                    </div>
                                    <div className='location d-flex mb-3 pb-1'>
                                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.49992 8.95334C9.64867 8.95334 10.5799 8.02209 10.5799 6.87334C10.5799 5.72458 9.64867 4.79333 8.49992 4.79333C7.35117 4.79333 6.41992 5.72458 6.41992 6.87334C6.41992 8.02209 7.35117 8.95334 8.49992 8.95334Z" stroke="#101820" />
                                            <path d="M2.91349 5.65998C4.22682 -0.113352 12.7802 -0.106685 14.0868 5.66665C14.8535 9.05331 12.7468 11.92 10.9002 13.6933C9.56015 14.9866 7.44015 14.9866 6.09349 13.6933C4.25349 11.92 2.14682 9.04665 2.91349 5.65998Z" stroke="#101820" />
                                        </svg>
                                        <span className='d-inline-block ms-2 fw-medium'>{[
                                            item?.address?.street,
                                            item?.address?.city,
                                            item?.address?.state,
                                            item?.address?.postcode,
                                            item?.address?.country
                                        ].filter(Boolean).join(', ')}
                                        </span>
                                    </div>
                                    <div className='row g-4'>
                                        <div className='col-6 bottom-col'>
                                            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.5 18.3334C15.1024 18.3334 18.8334 14.6024 18.8334 10C18.8334 5.39765 15.1024 1.66669 10.5 1.66669C5.89765 1.66669 2.16669 5.39765 2.16669 10C2.16669 14.6024 5.89765 18.3334 10.5 18.3334Z" stroke="#101820" strokeWidth="0.833333" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M7.16673 2.5H8.00006C6.37506 7.36667 6.37506 12.6333 8.00006 17.5H7.16673" stroke="#101820" strokeWidth="0.833333" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M13 2.5C14.625 7.36667 14.625 12.6333 13 17.5" stroke="#101820" strokeWidth="0.833333" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M3 13.3333V12.5C7.86667 14.125 13.1333 14.125 18 12.5V13.3333" stroke="#101820" strokeWidth="0.833333" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M3 7.5C7.86667 5.875 13.1333 5.875 18 7.5" stroke="#101820" strokeWidth="0.833333" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <div className='label my-2'>Language</div>
                                            <div className='value fw-semibold'>
                                                {item?.language_skills?.slice(0, 2).map((language, index) => (
                                                    <span key={index}>
                                                        {language.language}
                                                        {index === 0 && item.language_skills.length > 1 ? ", " : ""}
                                                    </span>
                                                ))}

                                                {item?.language_skills?.length > 2 && (
                                                    <span> +{item.language_skills.length - 2}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className='col-6 bottom-col'>
                                            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.5 10C12.8012 10 14.6667 8.13454 14.6667 5.83335C14.6667 3.53217 12.8012 1.66669 10.5 1.66669C8.19885 1.66669 6.33337 3.53217 6.33337 5.83335C6.33337 8.13454 8.19885 10 10.5 10Z" stroke="#292D32" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M3.3418 18.3333C3.3418 15.1083 6.55013 12.5 10.5001 12.5C11.3001 12.5 12.0751 12.6083 12.8001 12.8083" stroke="#292D32" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M18.8333 15C18.8333 15.625 18.6583 16.2167 18.35 16.7167C18.175 17.0167 17.95 17.2834 17.6916 17.5C17.1083 18.025 16.3416 18.3334 15.5 18.3334C14.2833 18.3334 13.2249 17.6834 12.6499 16.7167C12.3416 16.2167 12.1666 15.625 12.1666 15C12.1666 13.95 12.65 13.0084 13.4166 12.4C13.9916 11.9417 14.7166 11.6667 15.5 11.6667C17.3416 11.6667 18.8333 13.1584 18.8333 15Z" stroke="#292D32" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M14.2001 15L15.0251 15.825L16.8001 14.1833" stroke="#292D32" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <div className='label my-2'>Expertise</div>
                                            <div className='value fw-semibold'>{item.teaching_experience.slice(0, 2).filter(Boolean).join(', ')} <span> {item.teaching_experience.length > 2 ? ` +${item.teaching_experience.length - 2}` : ''}</span></div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default TutorsScreen;