import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { UserOutlined, AlignLeftOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, message } from "antd";
import axios from "axios";
import { galaxyKids } from "../../../assets/styles/globle";

const EditWorkExperience = (props) => {
  const { isModalVisibleWork, setIsModalVisibleWork } = props;
  const [form] = Form.useForm();

  const handleCancel = () => {
    setIsModalVisibleWork(false);
  };

  useEffect(() => {
    const fetchTeacher = async () => {
      try {
        const token = localStorage.getItem("accessToken");
        const teacherId = localStorage.getItem("teacherId");
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/teacher/${teacherId}`
        );

        const workExperienceData = response?.data?.data?.work_experience || [];
        const formattedData = workExperienceData.map((exp) => ({
          position: exp.position || "",
          company_name: exp.company_name || [],
          details: exp.details || "",
        }));
        form.setFieldsValue({ work_experience: formattedData });
      } catch (err) {
        console.log("Error fetching teacher data:", err);
      }
    };

    if (isModalVisibleWork) {
      fetchTeacher();
    }
  }, [isModalVisibleWork]);

  const onFinish = async (values) => {
    const token = localStorage.getItem("accessToken");
    const teacherId = localStorage.getItem("teacherId");
    const workExperienceData = values.work_experience || [];

    const isAnyFormFilled = workExperienceData.some(
      (form) => form.position || form?.company_name?.length || form.details
    );
    if (!isAnyFormFilled) {
      message.error("Please fill out at least one work experience form.");
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/teacher/basic-details/${teacherId}`,
        { work_experience: workExperienceData },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      message.success("Work experience details saved successfully!");
      form.resetFields();
      handleCancel();
    } catch (error) {
      console.error("Error saving work experience details:", error);
      message.error("Failed to save work experience details. Please try again.");
    }
  };

  const handleCompanyNameChange = (value, name) => {
    const workExperience = form.getFieldValue('work_experience');
    if (value.length > 1) {
      workExperience[name].company_name = [value[value.length - 1]];
      form.setFieldsValue({ work_experience: workExperience });
    }
  };

  return (
    <Modal
      isOpen={isModalVisibleWork}
      toggle={handleCancel}
      contentClassName="custom-modal-content"
      backdropClassName="custom-modal-backdrop"
    >
      <ModalHeader
        toggle={handleCancel}
        style={{
          fontSize: "18px",
          fontWeight: "bold",
          color: galaxyKids.textOrange,
          backgroundColor: galaxyKids.backgroundColor,
        }}
      >
        Work Experience Details
      </ModalHeader>
      <ModalBody
        className="custom-modal-body"
        style={{ backgroundColor: galaxyKids.backgroundColor }}
      >
        <Form
          form={form}
          layout="vertical"
          name="workExperienceForm"
          onFinish={onFinish}
          initialValues={{ work_experience: [{ position: "", company_name: [], details: "" }] }}
        >
          <Form.List name="work_experience">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <div key={key}>
                    <Form.Item
                      {...restField}
                      name={[name, "position"]}
                      fieldKey={[fieldKey, "position"]}
                      label="Position"
                      rules={[{ required: true, message: "Please input the position!" }]}
                    >
                      <Input
                        prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder="Position"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "company_name"]}
                      fieldKey={[fieldKey, "company_name"]}
                      label="Company Name"
                      rules={[
                        { required: true, message: "Please select company name!" }
                      ]}
                    >
                      <Select
                        mode="tags"
                        placeholder="Company Name"
                        style={{ width: "100%" }}
                        onChange={(value) => handleCompanyNameChange(value, name)}
                      >
                        <Select.Option value="Ava Swift">Ava Swift</Select.Option>
                        <Select.Option value="Cole Reed">Cole Reed</Select.Option>
                        <Select.Option value="Mia Blake">Mia Blake</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "details"]}
                      fieldKey={[fieldKey, "details"]}
                      label="Description"
                      rules={[{ required: true, message: "Please input details!" }]}
                    >
                      <Input.TextArea
                        prefix={<AlignLeftOutlined className="site-form-item-icon" />}
                        placeholder="Description"
                        rows={4}
                      />
                    </Form.Item>
                    {fields.length > 1 && (
                      <Button
                        type="danger"
                        onClick={() => remove(name)}
                        style={{ float: "right" }}
                        icon={<DeleteOutlined />}
                      >
                        Delete
                      </Button>
                    )}
                  </div>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: "100%", marginBottom: "10px" }}
                  >
                    Add Work Experience
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </ModalBody>
      <ModalFooter
        className="custom-modal-footer"
        style={{ backgroundColor: galaxyKids.backgroundColor }}
      >
        <Button
          className="border-0"
          style={{ color: galaxyKids.textOrange, backgroundColor: galaxyKids.backgroundColor }}
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          className="border-0"
          style={{ backgroundColor: galaxyKids.textOrange, color: "#fff" }}
          onClick={() => form.submit()}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditWorkExperience;
