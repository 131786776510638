import React, { useEffect, useState } from "react";
import { Form, Input, Button, Divider, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "../../Header";
import { galaxyKids } from "../../../assets/styles/globle";
import Footer from "../../FooterPart";
import Cookies from "js-cookie";

const AdminLogin = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      navigate("/");
    }
    setAccessToken(accessToken);
  }, [navigate]);

  const handleSubmit = async (values) => {
    const { email, password } = values;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/login`,
        { email, password }
      );
      // Store admin ID and access token in cookies
      Cookies.set("adminId", response?.data?.data?.admin?._id);
      Cookies.set("adminAccessToken", response?.data?.data?.accessToken);
      message.success(response?.data?.message);
      navigate("/admin/dashboard"); // Redirect to admin dashboard
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <div className="container-fluid">
      {/* <Header /> */}
      <div
        className="row no-gutter"
        style={{ backgroundColor: galaxyKids.backgroundColor }}
      >
        <div className="col-md-6 d-none d-md-flex bg-image">
          <img
            src={require("../../../assets/images/register.png")}
            alt="Your Image"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>
        <div className="col-md-6 py-4">
          <div className="login d-flex align-items-center justify-content-center py-4">
            <div className="container py-4">
              <div className="row py-4">
                <div className="col-lg-10 mx-auto py-4">
                  <h2
                    style={{
                      color: galaxyKids.textOrange,
                      fontWeight: "500",
                      textAlign: "center",
                      fontSize: "32px",
                    }}
                    className="py-4"
                  >
                    Login for Admin
                  </h2>
                  <Form
                    form={form}
                    onFinish={handleSubmit}
                    initialValues={{ email: "", password: "" }}
                  >
                    <Form.Item
                      name="email"
                      rules={[
                        { required: true, message: "Please enter your email" },
                      ]}
                    >
                      <Input
                        className="shadow p-3 bg-white rounded"
                        type="email"
                        placeholder="Enter Your Email"
                      />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your password",
                        },
                      ]}
                    >
                      <Input.Password
                        className="shadow p-3 bg-white rounded"
                        placeholder="Enter Your Password"
                      />
                    </Form.Item>
                    {/* <p
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <Link
                        to={"/forgotpassword"}
                        style={{ textDecoration: "none" }}
                      >
                        Forgot password?
                      </Link>
                    </p> */}
                    <div className="mb-3">
                      <Divider plain>OR</Divider>
                    </div>
                    {/* <div className="d-flex justify-content-center">
                      <Button
                        style={{ height: "auto", width: "150px" }}
                        className="mb-2 mr-0 mr-md-2 text-dark bg-white border-0 shadow p-3 mb-5 bg-white rounded"
                      >
                        <img
                          src={require("../../../assets/images/google1.png")}
                          alt="Your Image"
                          style={{ width: "30px", height: "30px" }}
                        />
                        &nbsp; Google
                      </Button>
                    </div> */}
                    <div className="d-flex justify-content-center">
                      <Button
                        className="shadow p-3 rounded"
                        style={{
                          backgroundColor: galaxyKids.textOrange,
                          border: "none",
                          width: "50%",
                          height: "50px",
                        }}
                        htmlType="submit"
                      >
                        LOGIN
                      </Button>
                    </div>
                    {/* <p className="mt-3 text-center">
                      Don't have an Account?{" "}
                      <span style={{ color: galaxyKids.textColor }}>
                        <Link
                          to={"/register"}
                          style={{ textDecoration: "none" }}
                        >
                          Register
                        </Link>
                      </span>
                    </p> */}
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default AdminLogin;
