import React from "react";
import "react-toastify/dist/ReactToastify.css";
import Register from "./components/Register";
import TutorsScreen from "./components/TutorsScreen";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import TutorPage from "./components/Profile/TutorPage";
import LoginPage from "./components/LoginPage";
import { ToastContainer } from "react-toastify";
import ProfileInformation from "./components/ProfileInformation/ProfileInformation";
import Protected from "./components/private/Protected";
import "./App.css";
import DashBoard from "./components/dashboard/DashBoard";
import Profile from "./components/dashboard/Profile";
import { Button, Result } from "antd";
import Supplemental from "./components/dashboard/Supplemental";
import Quiz from "./components/dashboard/Quiz";
import Thanks from "./components/dashboard/Thanks";
import ForgotPasswordPage from "./components/loginComponents/ForgotPasswordPage";
import AdminLogin from "./components/admin/LoginComponents/AdminLogin";
import ResetPassword from "./components/loginComponents/ResetPassword";
import AdminRoutes from "./components/admin/LoginComponents/AdminRoutes";
import { CountryProvider } from '../src/context/CountryContext';

//import nwe design css
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './assets/css/style.css'
import './assets/css/responsive.css'
import './assets/css/font.css'

import Layout from "./components/layout/Layout";
const App = () => {
  return (

    <>
      <AdminRoutes />
      <ToastContainer />
      <CountryProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Layout />}>

              <Route path="/" element={<TutorsScreen />} />
              <Route path="/dashboard" element={ <Protected> <DashBoard /> </Protected> } />
              <Route path="/profile" element={ <Protected> <Profile /> </Protected> } />
              <Route path="/tutor/:id/:tab?" element={<TutorPage />} />
              <Route path="/profileinformation" element={ <Protected> <ProfileInformation /> </Protected> } />
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<LoginPage />} />
            
              <Route path="/success" element={<Thanks />} />
              <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
              <Route path="/resetpassword" element={<ResetPassword />} />

              <Route path="/supplemental" element={<Supplemental />} />
              <Route path="/quiz" element={<Quiz />} />

            </Route>
          </Routes>
        </Router>
      </CountryProvider>
    </>
  );
};

export default App;
